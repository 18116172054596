import { EMISSIONS_HOME_FILTERS } from "#batteries-included-components/Layouts/HomeEmissions/EmissionsHomeFilterPanel";
import { NODE_API_MAX_PAGE_SIZE } from "#services/ServiceHelper";
import { INIT_POLLUTANT_AS_MASS } from "#src/batteries-included-components/Dropdowns/PollutantsDropdown";
import { MASS_POLLUTANTS } from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getIntervalInMonthsFormatted, hydrateDateRange } from "#utils/date";
import { useQuery } from "@tanstack/react-query";
import {
  MetricTile,
  NumberDataDisplay,
  StorageKeys,
  XYChart,
  useFilters,
  useToast,
} from "@validereinc/common-components";
import { CalculatorResultsDomain } from "@validereinc/domain";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import { compareAsc, format, parse } from "date-fns";
import startCase from "lodash/startCase";
import React, { useMemo } from "react";
import { patchMissingDataWithZeros } from "../utils/patch-missing-data-with-zeros";

type DataPoint = {
  date: Date;
  year_month: string;
  measurement: Record<string, number>;
};

type DataSetType = {
  label: string;
  variant: string;
  axis: string;
  data: DataPoint[];
};

const BAR_CHART_HEIGHT = 300;

const sortFunction = (
  { date: aDate }: { date: Date },
  { date: bDate }: { date: Date }
) => compareAsc(aDate, bDate);

/**
 * Returns a bar chart of the emissions for the selected facility over time
 */
export const EmissionsHomeMetricTileXYChart = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { toast } = useToast();
  const { getUnitByType, getPrecisionByType, getUnitName } =
    useMeasurementTypes();
  const [
    {
      [EMISSIONS_HOME_FILTERS.dateRange.name]: dateRange,
      [EMISSIONS_HOME_FILTERS.pollutant.name]: pollutant,
      [EMISSIONS_HOME_FILTERS.reportingScenario.name]: reportingGroup,
    },
  ] = useFilters(filterConfigStorageKey);

  const { mass, label } =
    MASS_POLLUTANTS.find((pol) => pol.dataKey === pollutant) ??
    INIT_POLLUTANT_AS_MASS;
  const precision = getPrecisionByType(mass);
  const unit = getUnitName(getUnitByType(mass));

  const title = `Total ${label}`;

  const enabled = !!reportingGroup && !!dateRange?.from && !!dateRange?.to;

  const onError = () =>
    toast.push({
      intent: "error",
      description:
        "There was an error fetching your facility emissions over time",
    });

  const barChartRequest = {
    page: 1,
    pageSize: NODE_API_MAX_PAGE_SIZE,
    sortBy: "year_month",
    sortDirection: "desc",
    filters: {
      measurement_type: {
        $in: ["mass_ch4", "mass_co2", "mass_co2eq", "mass_n2o"],
      },
      reporting_group: reportingGroup,
      year_month: {
        $in: getIntervalInMonthsFormatted(hydrateDateRange(dateRange)),
      },
      isAlreadyFormatted: true,
    },
    groupBy: ["year_month"],
  };

  const barChartQuery = useQuery({
    queryKey: ["calculatorResults", barChartRequest],
    queryFn: () => CalculatorResultsDomain.getList(barChartRequest),
    select: ({ data }) => data,
    enabled,
    onError,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const metricTileRequest = {
    page: 1,
    pageSize: 1,
    filters: {
      reporting_group: reportingGroup,
      year_month: {
        $in: getIntervalInMonthsFormatted(hydrateDateRange(dateRange)),
      },
      isAlreadyFormatted: true,
    },
  };

  const metricTileQuery = useQuery({
    queryKey: ["calculatorResults", metricTileRequest],
    queryFn: () => CalculatorResultsDomain.getList(metricTileRequest),
    select: ({ data: [{ measurement }] }) => measurement,
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const facilityEmissions = useMemo(() => {
    if (!barChartQuery.data) {
      return [];
    }

    try {
      // patches any missing pollutants with 0 instead of undefined so that the chart doesn't break
      const data = patchMissingDataWithZeros(barChartQuery.data);

      return data
        .reduce((total: DataSetType[], record): DataSetType[] => {
          const label = record["equipment.type.name"];
          const date = parse(record.year_month, "yyyyMM", new Date());

          const setIndex = total.findIndex(
            ({ label: currentLabel }) => currentLabel === label
          );

          if (setIndex >= 0) {
            total[setIndex].data.push({
              ...record,
              date,
            });

            return total;
          }

          return [
            ...total,
            {
              label,
              variant: "bar",
              axis: "left",
              data: [
                {
                  ...record,
                  date,
                },
              ],
            },
          ];
        }, [] as DataSetType[])
        .map(({ label, data, ...restSet }) => ({
          ...restSet,
          label: startCase(label),
          data: data.sort(sortFunction),
        }));
    } catch (error) {
      onError();
      return [];
    }
  }, [barChartQuery.data]);
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "16px 0px",
        }}
      >
        <MetricTile
          style={{ flexGrow: 1 }}
          isLoading={metricTileQuery.isLoading}
          title={title}
          value={(props) => (
            <NumberDataDisplay
              {...props}
              value={metricTileQuery.data?.[mass]}
              {...(precision ? { fixedFractionDigits: precision } : {})}
            />
          )}
          unit={unit}
        />
      </div>
      <XYChart
        height={BAR_CHART_HEIGHT}
        sets={facilityEmissions}
        isLegendVisible={false}
        isBrushVisible={false}
        leftAxis={{
          label: `${label} (${unit})`,
          valueAccessor: (data: DataPoint) => data.measurement[mass],
          valueRenderer: (data: DataPoint) =>
            getFormattedNumberWithUnit(
              { value: data.measurement[mass], unit },
              precision
            ),
        }}
        bottomAxis={{
          compare: sortFunction,
          tickRenderer: (date: string) => format(new Date(date), "MMM yyyy"),
          valueAccessor: ({ date }: DataPoint) => date,
        }}
      />
    </>
  );
};
