import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import { QuestionField } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField/QuestionField";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { FilterArea, useFilterAreaContext } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  useSessionStickyState,
  useStickyState,
} from "#src/hooks/useStickyState";
import {
  getStorageKey,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { useNavigate } from "#src/Routers/hooks";
import { TemplatedReportsListRoutePath } from "#src/routes/reports/templated-reports";
import { TemplatedReportsCategoriesDetailRoutePath } from "#src/routes/reports/templated-reports/categories/[categoryId]";
import { TemplatedReportsTemplateDetailRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]";
import { TemplatedReportsGeneratedReportDetailsRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]/generated/[reportId]";
import { getGeneratedReportWithIdQueueStorageKey } from "#src/routes/reports/templated-reports/template/[templateName]/generated/[reportId]/TemplatedReportGeneratedReportDetailsPage.helpers";
import { getGeneratedReportQueueStorageKey } from "#src/routes/reports/templated-reports/template/[templateName]/TemplatedReportsTemplateDetailsPage.helpers";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { displayValueByDataType } from "#src/utils/display";
import { ExceptionUtils } from "#src/utils/exception";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  Banner,
  BooleanDataDisplay,
  Button,
  Chip,
  DataTable,
  DataTablePanel,
  DateDataDisplay,
  DateSelectorInput,
  Dialog,
  Drawer,
  DropdownMenu,
  EmptyState,
  Form,
  Icon,
  KeyValueList,
  Link,
  Page,
  PanelCollapsible,
  Pill,
  PillPopover,
  PillToggleGroup,
  PillToggleVariants,
  TextDataDisplay,
  TextInput,
  Tooltip,
  useForm,
  useToast,
  type BannerProps,
  type HeaderType,
  type IconVariants,
  type PillPopoverOptionType,
  type PillPopoverType,
  type PillToggleProps,
} from "@validereinc/common-components";
import {
  BaseError,
  CreateReportV3Schema,
  getFilterObject,
  HTTPResponseError,
  isResponseErrorType,
  ReportGeneratedStatus,
  ReportStatusV3,
  ReportV3Adapter,
  ReportV3Schema,
  Resources,
  SortDirection,
  TemplatedReportAdapter,
  type AttributeDataTypeType,
  type CreateReportV3Type,
  type ReportGeneratedStatusType,
  type ReportStatusV3Type,
  type ReportV3Type,
  type ReportVersionV3Type,
  type ReportWithVersionV3Type,
  type TemplatedReportInputType,
  type UserType,
} from "@validereinc/domain";
import {
  DateFormats,
  datetimeFormatter,
  downloadLink,
  getNameAbbreviation,
  toFlattenedObject,
  toStartCaseString,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import debounce from "lodash/debounce";
import isPlainObject from "lodash/isPlainObject";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import styles from "./TemplatedReportsTemplateDetailsPage.module.css";

const cx = classNames.bind(styles);
const sanitizeValues = (inputs: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(inputs)
      .filter(([_, value]) => !!value && value !== "")
      .map(([key, value]) => {
        if (isPlainObject(value)) {
          return [key, sanitizeValues(value)];
        }

        if (value instanceof Date) {
          return [key, value.toISOString()];
        }

        return [key, value];
      })
  );

export const TemplatedReportsTemplateDetailsPage = () => {
  const {
    v2: {
      companyInfo: { company },
    },
  } = useAuthenticatedContext();
  const { templateName } =
    useParams<
      Exclude<
        typeof TemplatedReportsTemplateDetailRoutePath.pathParams,
        undefined
      >
    >();
  const { filterConfigStorageKey, tableConfigStorageKey } = useStorageKey(
    `templated-reports-${templateName}-template-details`
  );
  const { filterConfigStorageKey: quickFilterConfigStorageKey } = useStorageKey(
    `templated-reports-${templateName}-template-details-quick-filters`
  );
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isRerunReportDialogOpen, setIsRerunReportDialogOpen] = useState(false);
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<ReportV3Type | null>(
    null
  );
  const [
    isTemplateRemoveConfirmationOpen,
    setIsTemplateRemoveConfirmationOpen,
  ] = useState<boolean>(false);
  const { data: templateDetails, isLoading: isTemplateDetailsLoading } =
    useQuery({
      queryKey: [Resources.TEMPLATED_REPORT, templateName],
      queryFn: () => {
        if (!templateName) return;

        return TemplatedReportAdapter.getOne({ id: templateName });
      },
      enabled: !!templateName,
      select: (resp) => resp?.data,
      staleTime: 3 * 60 * 1000,
    });

  const pageTitle = templateDetails?.display_name;
  const [breadcrumbs, isBreadcrumbsLoading] = useBreadcrumbsFromRoute(
    TemplatedReportsTemplateDetailRoutePath,
    {
      "/:templateName": {
        title: pageTitle,
      },
    }
  );

  const generateNewReportForm = useForm<
    Omit<CreateReportV3Type, "templated_report_name">
  >({
    defaultValues: {
      name: templateDetails?.display_name,
    },
  });

  const generatedReportQueueStorageKey = useCustomStorageKey(
    getGeneratedReportQueueStorageKey(templateName ?? ""),
    "report"
  );
  const [generateReportJobDetails, setGenerateReportJobDetails] =
    useStickyState<{
      id?: ReportV3Type["id"];
      version?: ReportVersionV3Type["version"];
      isRegenerated?: boolean;
      isSuccess?: boolean;
      error?: unknown;
    }>({}, generatedReportQueueStorageKey);
  const terminalGeneratedReportStatuses: ReportGeneratedStatusType[] = [
    ReportGeneratedStatus.SUCCESS,
    ReportGeneratedStatus.FAILED,
  ];

  const downloadReportMutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { data: reportData } = await ReportV3Adapter.getOne({ id });
      // REVIEW: why doesn't the overall report have a download link? hence why a specific version fetch needs to happen
      const { data: reportVersionData } = await ReportV3Adapter.versions.getOne(
        {
          id: reportData.id,
          meta: { version: reportData.latest_version.version },
        }
      );

      if (!reportVersionData.s3_download_link) {
        throw new BaseError("Report download link does not exist.", {
          getExplanation: () =>
            "Failed to download report: download link does not exist.",
        });
      }

      downloadLink(reportVersionData.s3_download_link, reportVersionData.name);
    },
    onError: (error) => {
      toast.push({
        intent: "error",
        description: "Unable to download report",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "TemplatedReportsTemplateDetailsPage",
      });
    },
  });

  const {
    data: generatedReport,
    isLoading: generatedReportIsLoading,
    error: generatedReportError,
  } = useQuery({
    queryKey: [
      Resources.REPORT,
      generateReportJobDetails?.id,
      generateReportJobDetails?.version,
    ],
    queryFn: () => {
      if (!generateReportJobDetails?.id || !generateReportJobDetails.version)
        return;

      return ReportV3Adapter.versions.getOne({
        id: generateReportJobDetails.id,
        meta: { version: generateReportJobDetails.version },
      });
    },
    enabled:
      !!generateReportJobDetails?.id && !!generateReportJobDetails.version,
    staleTime: 10 * 60 * 1000,
    refetchIntervalInBackground: true,
    refetchInterval: (data, query) => {
      if (query.state.dataUpdateCount > 100) {
        if (!generateReportJobDetails.error) {
          setGenerateReportJobDetails((prevState) => ({
            ...prevState,
            error: "Report stuck in 'exporting' state",
          }));

          // the report details page keeps the exact same job in this queue - remove it
          if (templateName && generateReportJobDetails?.id && company?.id)
            window.localStorage.removeItem(
              getStorageKey(
                getGeneratedReportWithIdQueueStorageKey(
                  templateName,
                  generateReportJobDetails?.id
                ),
                "report",
                company.id
              )
            );
        }

        return false;
      }

      return data?.data.version?.status &&
        !terminalGeneratedReportStatuses.includes(data.data?.version?.status)
        ? query.state.dataUpdateCount > 5
          ? 3500
          : 1500
        : false;
    },
  });

  const generateReportMutation = useMutation({
    mutationFn: (params: Parameters<typeof ReportV3Adapter.createOne>[0]) => {
      if (!templateName) {
        toast.push({
          intent: "error",
          description: "Failed to run report: report name not specified",
        });
        throw new BaseError("Failed report generation validation.", {
          getExplanation: () => "Templated Report Name not present.",
        });
      }

      const payload = sanitizeValues(params.data);
      const validationResult = CreateReportV3Schema.safeParse(payload);

      if (!validationResult.success) {
        console.error(validationResult.error);
        toast.push({
          intent: "error",
          description: "Failed to run report: invalid inputs",
        });
        throw new BaseError("Failed report generation validation.", {
          getExplanation: () => "Failed to run report: invalid inputs.",
          errors: [validationResult.error],
        });
      }

      return ReportV3Adapter.createOne({
        data: payload,
        meta: params.meta,
      });
    },
    onSuccess: (data, vars) => {
      queryClient.removeQueries({
        queryKey: [
          Resources.REPORT,
          generateReportJobDetails?.id,
          generateReportJobDetails?.version,
        ],
      });
      setGenerateReportJobDetails({
        id: data.data.id,
        isRegenerated: !!vars.meta?.enableNewVersion,
        version: data.data.latest_version.version,
      });
      // the report details page keeps the exact same job in this queue - add it too
      if (data.data.templated_report_name)
        window.localStorage.setItem(
          getStorageKey(
            getGeneratedReportWithIdQueueStorageKey(
              data.data.templated_report_name,
              data.data.id
            ),
            "report",
            company?.id
          ),
          JSON.stringify({
            id: data.data.id,
            isRegenerated: !!vars.meta?.enableNewVersion,
            version: data.data.latest_version.version,
          })
        );
      queryClient.invalidateQueries([Resources.REPORT]);
    },
    onError: (error) => {
      if (error instanceof HTTPResponseError) {
        if (error.status === 409) {
          setIsRerunReportDialogOpen(true);
          return;
        }
      }

      toast.push({
        intent: "error",
        description: "Failed to generate report",
      });
      ExceptionUtils.reportException(error, "warning", {
        sourceComponent: "TemplatedReportsTemplateDetailPage",
      });
    },
  });

  const changeReportStatusMutation = useMutation({
    mutationFn: (params: Parameters<typeof ReportV3Adapter.updateOne>[0]) => {
      return ReportV3Adapter.updateOne(params);
    },
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: "Successfully changed report status.",
      });
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    },
    onError: (error) => {
      toast.push({
        variant: "error",
        message: "Failed to update report status",
      });
      ExceptionUtils.reportException(error, "warning", {
        sourceComponent: "TemplatedReportsTemplateDetailsPage",
      });
    },
  });

  const removeTemplateMutation = useMutation({
    mutationFn: async (
      params: Parameters<typeof TemplatedReportAdapter.company.deleteOne>[0]
    ) => {
      const result = await TemplatedReportAdapter.company.deleteOne(params);

      if (!isResponseErrorType(result)) return result;

      throw new BaseError(`Failed to delete template ${params.id}`, {
        errors: [result.error],
      });
    },
    onSuccess: (_, variables) => {
      toast.push({
        intent: "success",
        description: `Successfully removed template${variables.previousData?.display_name ? ` ${variables.previousData.display_name}.` : "."}`,
      });
      queryClient.invalidateQueries({ queryKey: [Resources.TEMPLATED_REPORT] });
      navigate(
        TemplatedReportsListRoutePath.toLinkParts({
          queryParams: {
            tab: "templates",
          },
        })
      );
    },
    onError: (error, variables) => {
      toast.push({
        intent: "error",
        description: `Failed to remove template${variables.previousData?.display_name ? ` ${variables.previousData.display_name}.` : "."}`,
      });
      ExceptionUtils.reportException(error, "error", {
        hint: `Failed to remove template ${variables.id} from company ${variables.meta?.company_id}`,
      });
    },
  });

  const deleteReportMutation = useMutation({
    mutationFn: async (
      params: Parameters<typeof ReportV3Adapter.deleteOne>[0]
    ) => {
      const result = await ReportV3Adapter.deleteOne(params);

      if (!isResponseErrorType(result)) return result;

      throw new BaseError(`Failed to delete report ${params.id}`, {
        errors: [result.error],
      });
    },
    onSuccess: (_, variables) => {
      toast.push({
        intent: "success",
        description: `Successfully deleted report${variables.previousData?.name ? ` ${variables.previousData.name}.` : "."}`,
      });
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    },
    onError: (error, variables) => {
      toast.push({
        intent: "error",
        description: `Failed to delete report${variables.previousData?.name ? ` ${variables.previousData.name}.` : "."}`,
      });
      ExceptionUtils.reportException(error, "error", {
        hint: `Failed to delete report ${variables.id}`,
      });
    },
  });

  const [generatedReportFilters] = useSessionStickyState(
    {},
    filterConfigStorageKey
  );
  const [generatedReportFiltersQuick] = useSessionStickyState(
    {},
    quickFilterConfigStorageKey
  );
  const allGeneratedReportFilters = {
    ...generatedReportFilters,
    ...generatedReportFiltersQuick,
  };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "latest_version.created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    allGeneratedReportFilters
  );
  const { updated_at: updatedAtFilter, ...restAllGeneratedReportFilters } =
    allGeneratedReportFilters;
  const generatedReportsQueryParams: Parameters<
    typeof ReportV3Adapter.getList
  >[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      templated_report_name: templateName ?? null,
      ...getFilterObject({
        updated_at: updatedAtFilter,
        ...toFlattenedObject(restAllGeneratedReportFilters),
      }),
      isAlreadyFormatted: true,
    },
  };
  const {
    data: generatedReports,
    isLoading: isGeneratedReportsLoading,
    isFetching: isGeneratedReportsFetching,
  } = useQuery({
    queryKey: [Resources.REPORT, generatedReportsQueryParams],
    queryFn: () => {
      if (!templateName) return;

      return ReportV3Adapter.getList(generatedReportsQueryParams);
    },
    enabled: !!templateName,
    staleTime: 3 * 60 * 1000,
  });
  const generatedReportEntries = generatedReports?.data ?? [];

  const [inputsToSearch, setInputsToSearch] = useState<Pick<
    CreateReportV3Type,
    "input"
  > | null>(null);
  const inputsToSearchWatchCallbackDebounced = useCallback(
    debounce((data, { name, type }) => {
      if (!name.includes("input.") || type !== "change") return;

      setInputsToSearch(sanitizeValues(data.input ?? {}));
    }, 400),
    []
  );
  const dupeReportsParams: Parameters<typeof ReportV3Adapter.getList>[0] = {
    page: 1,
    pageSize: 5,
    sortBy: "created_at",
    sortDirection: "desc",
    filters: {
      templated_report_name: templateName ?? null,
      input: inputsToSearch ?? {},
      isAlreadyFormatted: true,
    },
  };
  const {
    data: dupeReports,
    isFetching: dupeReportsIsFetching,
    isLoading: dupeReportsIsLoading,
    isInitialLoading: dupeReportsIsInitialLoading,
  } = useQuery({
    queryKey: [Resources.REPORT, dupeReportsParams],
    queryFn: () => ReportV3Adapter.getList(dupeReportsParams),
    enabled: inputsToSearch !== null && generateNewReportForm.formState.isDirty,
    staleTime: 5 * 60 * 1000,
  });

  const usersQuery = useGetManyUsers(
    Array.from(
      new Set(
        generatedReportEntries.flatMap(
          ({ created_by, updated_by, latest_version }) => [
            created_by,
            updated_by,
            latest_version.created_by,
          ]
        )
      )
    ).filter((item) => !!item) as string[]
  );
  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  useEffect(() => {
    const { unsubscribe } = generateNewReportForm.watch(
      inputsToSearchWatchCallbackDebounced
    );

    return () => unsubscribe();
  }, [inputsToSearchWatchCallbackDebounced]);
  useEffect(() => {
    const status = generatedReport?.data?.version?.status;

    if (
      status === ReportGeneratedStatus.SUCCESS ||
      status === ReportGeneratedStatus.FAILED ||
      generatedReportError
    ) {
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    }
  }, [generatedReport?.data?.version?.status, generatedReportError]);

  const handleReportGenerationSubmit = (
    formValues: Omit<CreateReportV3Type, "templated_report_name">
  ) => {
    generateReportMutation.mutate({
      data: {
        // @ts-expect-error name overriden on purpose
        name: templateDetails?.display_name,
        ...formValues,
        templated_report_name: templateName ?? null,
      },
    });
  };

  const navigateToDuplicateReportDetails = () => {
    if (!dupeReports?.data?.[0].id) return;

    navigate(
      TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts({
        pathParams: {
          reportId: dupeReports.data[0].id,
          templateName,
        },
        queryParams: {
          version: dupeReports.data[0].latest_version.version,
        },
      })
    );
  };

  const getGeneratingReportBannerProps = (
    generatingReport: ReportWithVersionV3Type,
    templateName?: string,
    companyId?: string,
    error?: unknown,
    isRegenerated = false
  ): Pick<
    BannerProps,
    | "titleText"
    | "descriptionText"
    | "variant"
    | "color"
    | "isDismissable"
    | "onDismiss"
    | "actionContent"
  > => {
    if (error) {
      return {
        titleText: `Failed to ${isRegenerated ? "re-run" : "run"} the report.`,
        descriptionText:
          error instanceof BaseError
            ? error.getExplanation()
            : error instanceof Error
              ? error.message
              : String(error),
        color: "error",
        variant: "error",
        isDismissable: true,
        actionContent: generatingReport?.id ? (
          <>
            You can view the failed run of the report{" "}
            <RoutingLink
              to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts({
                pathParams: {
                  reportId: generatingReport?.id,
                  templateName: generatingReport?.templated_report?.name,
                },
              })}
            >
              {generatingReport?.name}
            </RoutingLink>{" "}
            on{" "}
            {
              <DateDataDisplay
                value={generatingReport?.version.created_at}
                isInline
                displayFormat={DateFormats.DATE_TIME}
              />
            }{" "}
            in its{" "}
            <RoutingLink
              to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts({
                pathParams: {
                  reportId: generatingReport.id,
                  templateName,
                },
              })}
            >
              run history
            </RoutingLink>
          </>
        ) : (
          <></>
        ),
        onDismiss: () => {
          queryClient.removeQueries({
            queryKey: [
              Resources.REPORT,
              generateReportJobDetails?.id,
              generateReportJobDetails?.version,
            ],
          });
          // the report details page keeps the exact same job in this queue - remove it too
          if (templateName && companyId && generateReportJobDetails?.id)
            window.localStorage.removeItem(
              getStorageKey(
                getGeneratedReportWithIdQueueStorageKey(
                  templateName,
                  generateReportJobDetails?.id
                ),
                "report",
                companyId
              )
            );
          setGenerateReportJobDetails({});
          queryClient.invalidateQueries({
            queryKey: [Resources.REPORT, generateReportJobDetails?.id],
          });
        },
      };
    }

    switch (generatingReport.version.status) {
      case "exporting":
        return {
          titleText: isRegenerated
            ? "Re-running report..."
            : "Running report...",
          color: "loading",
          variant: "loading",
          actionContent: (
            <>
              <RoutingLink
                to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts(
                  {
                    pathParams: {
                      reportId: generatingReport.id,
                      templateName,
                    },
                  }
                )}
              >
                View the running report
              </RoutingLink>
              .
            </>
          ),
        };
      case "success":
        return {
          titleText: `Successfully ${isRegenerated ? "re-ran" : "ran"} the report.`,
          color: "success",
          variant: "success",
          isDismissable: true,
          actionContent: (
            <>
              <RoutingLink
                to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts(
                  {
                    pathParams: {
                      reportId: generatingReport.id,
                      templateName,
                    },
                  }
                )}
              >
                View Details
              </RoutingLink>
            </>
          ),
          onDismiss: () => {
            queryClient.removeQueries({
              queryKey: [
                Resources.REPORT,
                generateReportJobDetails?.id,
                generateReportJobDetails?.version,
              ],
            });
            // the report details page keeps the exact same job in this queue - remove it too
            window.localStorage.removeItem(
              getStorageKey(
                getGeneratedReportWithIdQueueStorageKey(
                  templateName ?? "",
                  generateReportJobDetails?.id ?? ""
                ),
                "report",
                company?.id
              )
            );
            setGenerateReportJobDetails({});
            queryClient.invalidateQueries({
              queryKey: [Resources.REPORT, generateReportJobDetails?.id],
            });
          },
        };
      case "failed":
        return {
          titleText: `Failed to ${isRegenerated ? "re-run" : "run"} the report.`,
          color: "error",
          variant: "error",
          isDismissable: true,
          actionContent: (
            <>
              You can view the failed run of the report{" "}
              <RoutingLink
                to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts(
                  {
                    pathParams: {
                      reportId: generatingReport?.id,
                      templateName: generatingReport?.templated_report?.name,
                    },
                  }
                )}
              >
                {generatingReport?.name}
              </RoutingLink>{" "}
              on{" "}
              {
                <DateDataDisplay
                  value={generatingReport?.version.created_at}
                  isInline
                  displayFormat={DateFormats.DATE_TIME}
                />
              }{" "}
              in its{" "}
              <RoutingLink
                to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts(
                  {
                    pathParams: {
                      reportId: generatingReport.id,
                      templateName,
                    },
                  }
                )}
              >
                run history
              </RoutingLink>
            </>
          ),
          onDismiss: () => {
            queryClient.removeQueries({
              queryKey: [
                Resources.REPORT,
                generateReportJobDetails?.id,
                generateReportJobDetails?.version,
              ],
            });
            // the report details page keeps the exact same job in this queue - remove it too
            if (templateName && companyId && generateReportJobDetails?.id)
              window.localStorage.removeItem(
                getStorageKey(
                  getGeneratedReportWithIdQueueStorageKey(
                    templateName,
                    generateReportJobDetails?.id
                  ),
                  "report",
                  companyId
                )
              );
            setGenerateReportJobDetails({});
            queryClient.invalidateQueries({
              queryKey: [Resources.REPORT, generateReportJobDetails?.id],
            });
          },
        };
    }
  };

  const [inputs, inputHeaders] = useMemo<
    [
      Array<{ name: string } & TemplatedReportInputType>,
      Array<HeaderType<ReportV3Type>>,
    ]
  >(
    () =>
      templateDetails?.input_schema
        ? Object.entries(templateDetails?.input_schema).reduce<
            [
              Array<{ name: string } & TemplatedReportInputType>,
              Array<HeaderType<ReportV3Type>>,
            ]
          >(
            ([inputs, headers], [inputKey, inputConfig]) => {
              return [
                [
                  ...inputs,
                  {
                    name: inputKey,
                    ...inputConfig,
                  },
                ],
                [
                  ...headers,
                  {
                    label: inputConfig.display_name,
                    key: inputKey,
                    alignment:
                      inputConfig.data_type &&
                      (
                        ["number", "integer"] as AttributeDataTypeType[]
                      ).includes(inputConfig.data_type)
                        ? "right"
                        : "left",
                    renderComponent: ({ item }) => {
                      const inputValue = item.input[inputKey];

                      return displayValueByDataType(
                        inputValue,
                        inputConfig.data_type,
                        "-"
                      );
                    },
                  },
                ],
              ];
            },
            [[], []]
          )
        : [[], []],
    [templateDetails]
  );

  const reportStatusPillPopoverOptions: PillPopoverType<ReportStatusV3Type>["options"] =
    Object.entries(ReportStatusV3).map(([_, value]) => {
      return {
        label: toStartCaseString(value),
        value,
        icon: (
          {
            locked: "lock",
            unlocked: null,
            archived: null,
          } satisfies Record<ReportStatusV3Type, IconVariants | null>
        )[value],
        variant: (
          {
            locked: "default",
            unlocked: "info",
            archived: "warning",
          } satisfies Record<
            ReportStatusV3Type,
            PillPopoverOptionType<ReportStatusV3Type>["variant"]
          >
        )[value],
      };
    });
  const headers: Array<HeaderType<ReportV3Type>> = [
    {
      label: "Overview",
      key: "overview",
      headers: [
        {
          label: "Name",
          key: "name",
          isSortable: true,
          renderComponent: ({ item }) =>
            item.id && templateName ? (
              <RoutingLink
                to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts(
                  {
                    pathParams: {
                      reportId: item.id,
                      templateName,
                    },
                    queryParams: {
                      version: item.latest_version.version,
                    },
                  }
                )}
              >
                {item.name}
              </RoutingLink>
            ) : null,
        },
        {
          label: "Status",
          key: "status",
          isSortable: true,
          renderComponent: ({ item }) => (
            <PillPopover
              options={reportStatusPillPopoverOptions}
              value={item.status}
              isEditable
              isBusy={
                changeReportStatusMutation.variables?.id === item.id &&
                changeReportStatusMutation.isLoading
              }
              onChange={(newStatus: ReportStatusV3Type) =>
                changeReportStatusMutation.mutate({
                  id: item.id,
                  data: {
                    status: newStatus,
                  },
                })
              }
            />
          ),
        },
      ],
    },
    ...(inputHeaders.length
      ? [
          {
            label: "Inputs",
            key: "input",
            headers: inputHeaders,
          } satisfies HeaderType<ReportV3Type>,
        ]
      : []),
    {
      label: "Metadata",
      key: "metadata",
      headers: [
        {
          label: "Updated At",
          key: "latest_version.created_at",
          isSortable: true,
          renderComponent: ({ item }) => (
            <DataTable.DataRow.DateCell
              value={item.latest_version.created_at}
              withTime
            />
          ),
        },
        {
          label: "Updated By",
          key: "latest_version.created_by",
          isSortable: true,
          renderComponent: ({ item }) =>
            item.latest_version.created_by &&
            userMap[item.latest_version.created_by]?.name ? (
              <>
                <Chip
                  text={getNameAbbreviation(
                    userMap[item.latest_version.created_by].name
                  )}
                  tooltipText={userMap[item.latest_version.created_by].name}
                  style={{ marginRight: 4, minWidth: 26 }}
                />
                <RoutingLink
                  to={linkToUserDetailPage(item.latest_version.created_by)}
                >
                  {userMap[item.latest_version.created_by].name}
                </RoutingLink>
              </>
            ) : (
              "-"
            ),
        },
      ],
    },
  ];

  const filterKeys = ReportV3Schema.keyof().Enum;

  const StatusPillToggleGroup = ({
    onChange,
    name,
  }: {
    name: string;
    onChange: (val: string[], name: string) => void;
  }) => {
    const { storedFilters: { status } = {} } = useFilterAreaContext<{
      status: string[];
    }>();
    const pillToggles: PillToggleProps[] = [
      {
        name: "All",
        label: "All",
        value: "",
        isSelected: !status?.length || status?.includes(""),
        shouldSelectAll: true,
      },
      {
        name: ReportStatusV3.UNLOCKED,
        label: toStartCaseString(ReportStatusV3.UNLOCKED),
        value: ReportStatusV3.UNLOCKED,
        variant: PillToggleVariants.PENDING,
        isSelected: status?.includes(ReportStatusV3.UNLOCKED) ?? false,
      },
      {
        name: ReportStatusV3.LOCKED,
        label: toStartCaseString(ReportStatusV3.LOCKED),
        value: ReportStatusV3.LOCKED,
        variant: PillToggleVariants.NEUTRAL,
        isSelected: status?.includes(ReportStatusV3.LOCKED) ?? false,
      },
      {
        name: ReportStatusV3.ARCHIVED,
        label: toStartCaseString(ReportStatusV3.ARCHIVED),
        value: ReportStatusV3.ARCHIVED,
        variant: PillToggleVariants.ATTENTION,
        isSelected: status?.includes(ReportStatusV3.ARCHIVED) ?? false,
      },
    ];

    return (
      <PillToggleGroup
        name={name}
        pills={pillToggles}
        onChange={(val) => onChange(val, name)}
      />
    );
  };

  return (
    <>
      <Page
        title={
          pageTitle ? (
            <>
              {pageTitle}&nbsp;{" "}
              {templateDetails.status ? (
                <Pill
                  variant={
                    templateDetails.status === "active" ? "success" : "default"
                  }
                  hasDot={false}
                >
                  {templateDetails.status}
                </Pill>
              ) : null}
            </>
          ) : (
            TemplatedReportsTemplateDetailRoutePath.title
          )
        }
        breadcrumbs={breadcrumbs}
        category={TemplatedReportsTemplateDetailRoutePath.previous?.title}
        isLoading={isTemplateDetailsLoading || isBreadcrumbsLoading}
        actionRow={[
          <DropdownMenu
            key="more"
            options={[
              {
                label: "View Details",
                slotLeft: ({ iconProps }) => (
                  <Icon
                    {...iconProps}
                    variant="eye"
                  />
                ),
                onClick: () => setIsDetailsDrawerOpen(true),
              },
              {
                isSeparator: true,
              },
              {
                label: "Remove",
                variant: "danger",
                // REVIEW: can be enabled again pending fixes to come out of https://validere.slack.com/archives/C05V5C55G4R/p1738768979488809
                isDisabled: true,
                slotLeft: ({ iconProps }) => (
                  <Icon
                    {...iconProps}
                    variant="trash"
                  />
                ),
                onClick: () => setIsTemplateRemoveConfirmationOpen(true),
              },
            ]}
          >
            <Button
              icon="dots-three-vertical"
              variant="outline"
            />
          </DropdownMenu>,
        ]}
      >
        {generateReportJobDetails?.id &&
        !generatedReportIsLoading &&
        generatedReport?.data.version ? (
          <Banner
            className={cx("banner")}
            {...getGeneratingReportBannerProps(
              generatedReport.data,
              templateName,
              company?.id,
              generatedReportError ?? generateReportJobDetails.error,
              generateReportJobDetails.isRegenerated
            )}
          />
        ) : null}
        <div className={cx("container")}>
          <PanelCollapsible
            title="Report Details"
            titleDecorator={
              <Tooltip content="Generate a report by filling out the inputs and clicking 'Run'.">
                <Icon
                  variant="info"
                  className={cx("input-panel__title-icon")}
                />
              </Tooltip>
            }
            className={cx("input-panel")}
          >
            <Form
              {...generateNewReportForm}
              onSubmit={generateNewReportForm.handleSubmit(
                handleReportGenerationSubmit
              )}
            >
              <div className={cx("input-panel__inputs")}>
                {inputs.length ? (
                  inputs.map((input) => (
                    <QuestionField
                      {...input}
                      key={input.name}
                      name={`input.${input.name}`}
                      prompt={input.display_name}
                    />
                  ))
                ) : (
                  <EmptyState
                    title="No Inputs Required"
                    suggestion="This template does not require any inputs to run a report."
                    icon={<Icon variant="info" />}
                  />
                )}
              </div>
              <div className={cx("input-panel__footer")}>
                <TextInput
                  name="name"
                  label="Report Name"
                  type="text"
                  description={`Customize the display name of the report. If left blank, the name will be "${templateDetails?.display_name}"`}
                  placeholder="Enter Report Name..."
                />
                <div className={cx("input-panel__submit-triggers")}>
                  <Button
                    variant="outline"
                    onClick={() => generateNewReportForm.reset()}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={
                      generateReportMutation.isLoading ||
                      dupeReportsIsInitialLoading ||
                      dupeReportsIsFetching
                    }
                    disabled={
                      !generateNewReportForm.formState.isValid ||
                      (!!generateReportJobDetails?.id &&
                        generatedReportIsLoading)
                    }
                  >
                    Run
                  </Button>
                </div>
              </div>
            </Form>
          </PanelCollapsible>
          <DataTablePanel
            storageKey={tableConfigStorageKey}
            filterComponent={
              <FilterArea.Root storageKey={filterConfigStorageKey}>
                <FilterArea.Container
                  aria-label={`Filters for reports generated with the templated report ${templateDetails?.display_name}`}
                >
                  <FilterDrawer.Root>
                    <FilterArea.Content>
                      {({ handleOnChange }) => (
                        <div style={{ marginRight: 8, marginBottom: 0 }}>
                          <TextInput
                            name={filterKeys.name}
                            label="Name"
                            isLabelShown={false}
                            type="search"
                            isInline
                            placeholder="Search by Name..."
                            onChange={(val) =>
                              handleOnChange(val, filterKeys.name)
                            }
                          />
                        </div>
                      )}
                    </FilterArea.Content>
                    <FilterDrawer.Trigger />
                    <FilterDrawer.Content>
                      <Accordion defaultActiveKeys={["inputs"]}>
                        <Accordion.AccordionPanel
                          dataKey="overview"
                          title="Overview"
                        >
                          <DateSelectorInput
                            name={filterKeys.updated_at}
                            label="Updated At"
                            placeholder="Enter Updated At..."
                            isRange
                            variant="time"
                            isFluid
                            isInline={false}
                          />
                          <UsersDropdownInput
                            name={filterKeys.updated_by}
                            label="Updated By"
                            placeholder="Select Updated By..."
                          />
                        </Accordion.AccordionPanel>
                        <Accordion.AccordionPanel
                          dataKey="inputs"
                          title="Inputs"
                        >
                          {inputs.map((input) => (
                            <QuestionField
                              {...input}
                              key={input.name}
                              name={`input.${input.name}`}
                              prompt={input.display_name}
                            />
                          ))}
                        </Accordion.AccordionPanel>
                      </Accordion>
                    </FilterDrawer.Content>
                  </FilterDrawer.Root>
                </FilterArea.Container>
              </FilterArea.Root>
            }
            panelProps={{
              title: "Reports",
              titleDecorator: (
                <FilterArea.Root
                  storageKey={quickFilterConfigStorageKey}
                  defaultValues={{
                    status: [ReportStatusV3.LOCKED, ReportStatusV3.UNLOCKED],
                  }}
                  shouldMergeWhenApplyingDefaultValues={false}
                  applyDefaultValues
                >
                  <FilterArea.Container
                    aria-label={`Status filter for reports generated with the templated report ${templateDetails?.display_name}`}
                  >
                    <FilterArea.Content>
                      {({ handleOnChange }) => (
                        <StatusPillToggleGroup
                          name={filterKeys.status}
                          onChange={handleOnChange}
                        />
                      )}
                    </FilterArea.Content>
                  </FilterArea.Container>
                </FilterArea.Root>
              ),
              className: cx("table-panel"),
              loaded: !isTemplateDetailsLoading,
            }}
            dataTableProps={{
              isLoading: isGeneratedReportsLoading,
              isBusy: isGeneratedReportsFetching,
              variant: "simplicity-first",
              headers,
              items: generatedReportEntries ?? [],
              getItemActions: ({ item }) => {
                return [
                  {
                    label: "Export Latest Version",
                    buttonProps: {
                      icon: "arrow-square-out",
                      variant: "outline",
                      isLoading:
                        downloadReportMutation.variables?.id === item.id &&
                        downloadReportMutation.isLoading,
                      onClick: () => {
                        downloadReportMutation.mutate({
                          id: item.id,
                        });
                      },
                    },
                  },
                  {
                    isOverflow: true,
                    label: "Delete",
                    buttonProps: {
                      icon: "trash",
                      variant: "error",
                      isLoading:
                        deleteReportMutation.variables?.id === item.id &&
                        deleteReportMutation.isLoading,
                      onClick: () => {
                        setReportToDelete(item);
                      },
                    },
                  },
                ];
              },
              pagination: {
                page: tableState.page,
                pageSize: tableState.pageSize,
              },
              sorting: {
                sortBy: tableState.sortBy,
                sortDirection: tableState.sortDirection,
              },
              onSortChange: updateTableState,
              onPaginationChange: updateTableState,
            }}
          />
        </div>
      </Page>
      <Dialog
        title="Re-Run Report?"
        isOpen={isRerunReportDialogOpen}
        onClose={() => setIsRerunReportDialogOpen(false)}
        actionRow={[
          <Button
            key="rerun"
            variant="primary"
            isLoading={generateReportMutation.isLoading}
            onClick={() => {
              generateReportMutation
                .mutateAsync(
                  {
                    data: {
                      // @ts-expect-error name overriden on purpose
                      name: templateDetails?.display_name,
                      ...generateNewReportForm.getValues(),
                      templated_report_name: templateName ?? null,
                    },
                    meta: {
                      enableNewVersion: true,
                    },
                  },
                  {
                    onSuccess: () => {
                      setIsRerunReportDialogOpen(false);
                    },
                    onError: (error) => {
                      if (
                        error instanceof HTTPResponseError &&
                        isResponseErrorType(error.responseBodyJson)
                      ) {
                        if (
                          error.responseBodyJson.error.includes("not unlocked")
                        ) {
                          toast.push({
                            variant: "normal",
                            message: (
                              <>
                                A locked report with the selected inputs already
                                exists and cannot be overwritten.
                                {dupeReports?.data?.[0]?.id && templateName ? (
                                  <Link
                                    label="View Report Details"
                                    onClick={navigateToDuplicateReportDetails}
                                  />
                                ) : null}
                              </>
                            ),
                          });
                        }

                        return;
                      }

                      toast.push({
                        variant: "error",
                        message: "Failed to re-run report.",
                      });
                      ExceptionUtils.reportException(error, "error", {
                        hint: "Failed to re-run report from confirmation dialog.",
                      });
                    },
                  }
                )
                .finally(() => setIsRerunReportDialogOpen(false));
            }}
          >
            Re-Run Report
          </Button>,
          <Button
            key="goto"
            variant="outline"
            isLoading={dupeReportsIsLoading}
            onClick={navigateToDuplicateReportDetails}
          >
            Go To Report
          </Button>,
        ]}
      >
        A report with these input values already exists. Re-running the report
        will generate a new version and the previous version will still be
        accessible from within the report details.
      </Dialog>
      <Dialog
        title="Remove Template from Company?"
        isOpen={isTemplateRemoveConfirmationOpen}
        onClose={() => setIsTemplateRemoveConfirmationOpen(false)}
        actionRow={[
          <Button
            key="submit"
            variant="error"
            isLoading={deleteReportMutation.isLoading}
            onClick={() => {
              if (!templateName || !company?.id) return;

              removeTemplateMutation
                .mutateAsync({
                  id: templateName,
                  previousData: templateDetails,
                  meta: {
                    company_id: company?.id,
                  },
                })
                .finally(() => setIsTemplateRemoveConfirmationOpen(false));
            }}
          >
            Remove
          </Button>,
        ]}
      >
        Removing the template will remove all report runs and associated data.
        This action cannot be undone.
      </Dialog>
      <Dialog
        title={`Delete Report${reportToDelete?.name ? ` ${reportToDelete.name} created on ${datetimeFormatter(new Date(reportToDelete.created_at))}` : ""}?`}
        isOpen={reportToDelete !== null}
        onClose={() => setReportToDelete(null)}
        actionRow={[
          <Button
            key="submit"
            variant="error"
            isLoading={deleteReportMutation.isLoading}
            onClick={() => {
              if (!reportToDelete) return;

              deleteReportMutation
                .mutateAsync({
                  id: reportToDelete.id,
                  previousData: reportToDelete,
                })
                .finally(() => setReportToDelete(null));
            }}
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this report? This action cannot be
        undone.
      </Dialog>
      <Drawer
        title="Template Details"
        isOpen={isDetailsDrawerOpen}
        onClose={() => setIsDetailsDrawerOpen(false)}
      >
        <KeyValueList
          data={[
            {
              title: "ID",
              value: (
                <TextDataDisplay
                  value={templateDetails?.name}
                  alignment="right"
                />
              ),
            },
            {
              title: "Description",
              value: (
                <TextDataDisplay
                  value={templateDetails?.description}
                  alignment="right"
                />
              ),
            },
            {
              title: "Is Embedded?",
              value: (
                <BooleanDataDisplay
                  value={templateDetails?.is_embedded}
                  alignment="right"
                />
              ),
            },
            {
              title: "Category",
              value: templateDetails?.category?.id ? (
                <RoutingLink
                  to={TemplatedReportsCategoriesDetailRoutePath.toLinkParts({
                    pathParams: {
                      categoryId: templateDetails.category.id,
                    },
                  })}
                >
                  {templateDetails.category.name}
                </RoutingLink>
              ) : (
                "-"
              ),
            },
            {
              title: "Updated At",
              value: (
                <DateDataDisplay
                  value={templateDetails?.updated_at}
                  displayFormat={DateFormats.DATE_TIME}
                  alignment="right"
                />
              ),
            },
            {
              title: "Created At",
              value: (
                <DateDataDisplay
                  value={templateDetails?.created_at}
                  displayFormat={DateFormats.DATE_TIME}
                  alignment="right"
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  );
};
