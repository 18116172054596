import { DeleteCommentDialog } from "#src/batteries-included-components/Dialogs/DeleteCommentDialog";
import { CommentForm } from "#src/batteries-included-components/Forms/CommentForm";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetOneCommentFeed } from "#src/components/hooks/adapters/useComments";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import {
  useCheckCommentPermissions,
  useCommentsStore,
  useDeleteComment,
  useScrollToComment,
} from "#src/components/hooks/useComments";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#src/components/Redux/reducers/tableStateReducer";
import {
  useAuthenticatedContext,
  useHasPermission,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useStickyState } from "#src/hooks/useStickyState";
import {
  getStorageKey,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { useNavigate, useSearchParams } from "#src/Routers/hooks";
import { TemplatedReportsTemplateDetailRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]";
import { TemplatedReportsGeneratedReportDetailsRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]/generated/[reportId]";
import { getGeneratedReportWithIdQueueStorageKey } from "#src/routes/reports/templated-reports/template/[templateName]/generated/[reportId]/TemplatedReportGeneratedReportDetailsPage.helpers";
import { getGeneratedReportQueueStorageKey } from "#src/routes/reports/templated-reports/template/[templateName]/TemplatedReportsTemplateDetailsPage.helpers";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import {
  displayValueByDataType,
  displayValueWithUnknownDataType,
} from "#src/utils/display";
import { ExceptionUtils } from "#src/utils/exception";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  Banner,
  Button,
  ButtonToggleGroup,
  Chip,
  DataTable,
  DataTablePanel,
  DateDataDisplay,
  Dialog,
  Drawer,
  DropdownMenu,
  EmptyState,
  Icon,
  KeyValueList,
  KeyValueTileGrid,
  ListCard,
  Message,
  Page,
  Panel,
  PanelCollapsible,
  Pill,
  PillPopover,
  TimelinePanel,
  useTimelinePanelStore,
  useToast,
  type BannerProps,
  type HeaderType,
  type IconVariants,
  type PillPopoverOptionType,
  type PillPopoverType,
  type PillProps,
} from "@validereinc/common-components";
import {
  BaseError,
  CommentEntity,
  CommentSchemaType,
  HTTPResponseError,
  isResponseErrorType,
  ReportGeneratedStatus,
  ReportStatusV3,
  ReportV3Adapter,
  Resources,
  TemplatedReportAdapter,
  type ReportEmbeddedRowType,
  type ReportEmbeddedStyleVariantType,
  type ReportGeneratedStatusType,
  type ReportInputValueType,
  type ReportStatusV3Type,
  type ReportV3Type,
  type ReportVersionV3Type,
  type ReportWithVersionV3Type,
  type TemplatedReportInputType,
  type UserType,
} from "@validereinc/domain";
import {
  DateFormats,
  datetimeFormatter,
  downloadLink,
  getNameAbbreviation,
  toStartCaseString,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { z } from "zod";
import styles from "./TemplatedReportsGeneratedReportDetailsPage.module.scss";

const cx = classNames.bind(styles);

const { AccordionPanel } = Accordion;

const getSelectedReportVersion = (
  version?: ReportVersionV3Type["version"] | string
) => {
  const versionAsNumber = Number(version);
  const check = z.number().int().positive().safeParse(versionAsNumber);

  if (check.error) return 1;
  else return versionAsNumber;
};

const PanelContentType = {
  VERSIONS: "versions",
  REPORT_COMMENTS: "report_comments",
  ROW_COMMENTS: "row_comments",
} as const;
type PanelContentType =
  (typeof PanelContentType)[keyof typeof PanelContentType];

const REPORT_DRAWER_KEY = "report-drawer";

export const TemplatedReportsGeneratedReportDetailsPage = () => {
  const { reportId, templateName: templatedReportName } =
    useParams<
      Exclude<
        typeof TemplatedReportsGeneratedReportDetailsRoutePath.pathParams,
        undefined
      >
    >();
  const [searchParams, setSearchParams] = useSearchParams<{
    version: string;
  }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const {
    v2: {
      userInfo: { user },
      companyInfo: { company },
    },
  } = useAuthenticatedContext();
  const { canReadComments, canWriteComments, canDeleteCommentsAsAdmin } =
    useCheckCommentPermissions();
  const [canReadReports] = useHasPermission("reports:read");
  const [canWriteReports] = useHasPermission("reports:write");
  const {
    isOpen: isTimelinePanelOpen,
    setIsOpen: setIsTimelinePanelOpen,
    setTargetDrawer,
  } = useTimelinePanelStore();
  const {
    commentsEndRef,
    shouldScrollToBottom,
    newlyCreatedCommentId,
    handleCreateComment,
  } = useScrollToComment();
  const {
    handleDeleteComment,
    showDeleteCommentDialog,
    setShowDeleteCommentDialog,
    commentIdToDelete,
    setCommentIdToDelete,
  } = useDeleteComment();
  const { commentCounts, setCommentCounts } = useCommentsStore();
  const { measurementUnits } = useMeasurementTypes();
  const [reportToDelete, setReportToDelete] = useState<
    ReportWithVersionV3Type | ReportV3Type | null
  >(null);
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const [activePanelContent, setActivePanelContent] =
    useState<PanelContentType | null>(null);
  const [commentPanelActiveKey, setCommentPanelActiveKey] = useState("");
  const [selectedRow, setSelectedRow] = useState<
    Record<string, ReportEmbeddedRowType>
  >({});
  const currentReportVersion = getSelectedReportVersion(searchParams.version);

  const { tableConfigStorageKey: runHistoryTableConfigStorageKey } =
    useStorageKey(
      `templated-reports-${templatedReportName}-template-${reportId}-report-${currentReportVersion}-version`
    );
  const { tableConfigStorageKey: embeddedReportTableConfigStorageKey } =
    useStorageKey(
      `templated-reports-${templatedReportName}-template-${reportId}-report-embedded-${currentReportVersion}-version`
    );

  const generatedReportQueueStorageKey = useCustomStorageKey(
    getGeneratedReportWithIdQueueStorageKey(
      templatedReportName ?? "",
      reportId ?? ""
    ),
    "report"
  );
  const [generateReportJobDetails, setGenerateReportJobDetails] =
    useStickyState<{
      id?: ReportV3Type["id"];
      version?: ReportVersionV3Type["version"];
      isRegenerated?: boolean;
      isSuccess?: boolean;
      error?: unknown;
    }>({}, generatedReportQueueStorageKey);
  const terminalGeneratedReportStatuses: ReportGeneratedStatusType[] = [
    ReportGeneratedStatus.SUCCESS,
    ReportGeneratedStatus.FAILED,
  ];

  const { data: templateDetails, isLoading: isTemplateDetailsLoading } =
    useQuery({
      queryKey: [Resources.TEMPLATED_REPORT, templatedReportName],
      queryFn: () => {
        if (!templatedReportName) return;

        return TemplatedReportAdapter.getOne({ id: templatedReportName });
      },
      enabled: !!templatedReportName,
      select: (resp) => resp?.data,
      staleTime: 5 * 60 * 1000,
    });

  const defaultReportDetailsQuery = useQuery({
    queryKey: [Resources.REPORT, reportId],
    queryFn: () => {
      if (!reportId) return;

      return ReportV3Adapter.getOne({
        id: reportId,
      });
    },
    enabled: !!reportId,
    select: (resp) => resp?.data,
    staleTime: 3 * 60 * 1000,
  });

  const reportVersionDetailsQuery = useQuery({
    queryKey: [Resources.REPORT, reportId, currentReportVersion],
    queryFn: () => {
      if (!reportId || !currentReportVersion) return;

      return ReportV3Adapter.versions.getOne({
        id: reportId,
        meta: {
          version: currentReportVersion,
        },
      });
    },
    enabled: !!reportId && !!currentReportVersion,
    select: (resp) => resp?.data,
    staleTime: 30 * 60 * 1000,
  });

  const [embeddedRowsTableState, updateEmbeddedRowsTableState] =
    useTableSortingAndPagination({
      sortBy: undefined,
      sortDirection: undefined,
    });

  const embeddedReportRowDetailsQueryParams: Parameters<
    typeof ReportV3Adapter.embedded.rows.getList
  >[0] = {
    page: embeddedRowsTableState.page,
    pageSize: embeddedRowsTableState.pageSize,
    sortBy: embeddedRowsTableState.sortBy,
    sortDirection: embeddedRowsTableState.sortDirection,
    filters: {
      report_version: currentReportVersion,
    },
    meta: {
      report_id: reportId,
    },
  };
  const embeddedReportRowDetailsQuery = useQuery({
    queryKey: [
      Resources.REPORT,
      reportId,
      currentReportVersion,
      "embedded",
      "rows",
      embeddedReportRowDetailsQueryParams,
    ],
    queryFn: () => {
      return ReportV3Adapter.embedded.rows.getList(
        embeddedReportRowDetailsQueryParams
      );
    },
    enabled: !!templateDetails?.is_embedded && !!reportId,
    staleTime: 10 * 60 * 1000,
    retryDelay: 10 * 1000,
  });
  const reportRows = embeddedReportRowDetailsQuery.data?.data;

  const embeddedReportSheetDetailsQuery = useQuery({
    queryKey: [
      Resources.REPORT,
      reportId,
      currentReportVersion,
      "embedded",
      "sheets",
    ],
    queryFn: () => {
      if (!reportId) return;

      return ReportV3Adapter.embedded.sheets.getList({
        meta: {
          report_id: reportId,
          report_version: currentReportVersion,
        },
      });
    },
    enabled: !!templateDetails?.is_embedded && !!reportId,
    staleTime: 10 * 60 * 1000,
    retryDelay: 10 * 1000,
  });

  const {
    data: reportVersions,
    isLoading: isReportVersionsLoading,
    isFetching: isReportVersionsFetching,
  } = useQuery({
    queryKey: [Resources.REPORT, reportId, "versions"],
    queryFn: () => {
      if (!reportId) return;

      return ReportV3Adapter.versions.getList({ id: reportId });
    },
    enabled: !!reportId,
    staleTime: 3 * 60 * 1000,
  });

  const runInformation = useActionDetails({
    type: "run",
    date: templateDetails?.is_embedded
      ? reportVersionDetailsQuery.data?.version.created_at
      : defaultReportDetailsQuery.data?.latest_version.created_at,
    byUserId: templateDetails?.is_embedded
      ? reportVersionDetailsQuery.data?.version.created_by
      : defaultReportDetailsQuery.data?.latest_version.created_by,
  });

  const [runHistoryTableState, updateRunHistoryTableState] =
    useTableSortingAndPagination({
      sortBy: "created_at",
      sortDirection: "desc",
    });

  const {
    items: reportVersionsForTable,
    sorting: reportVersionsSorting,
    pagination: reportVersionsPagination,
  } = useClientSideSortingAndPagination(
    reportVersions?.data.versions ?? [],
    runHistoryTableState
  );

  const changeReportStatusMutation = useMutation({
    mutationFn: (params: Parameters<typeof ReportV3Adapter.updateOne>[0]) => {
      return ReportV3Adapter.updateOne(params);
    },
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: "Successfully updated report status.",
      });
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    },
    onError: (error) => {
      toast.push({
        intent: "error",
        description: "Failed to update report status.",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "TemplatedReportsGeneratedReportDetailsPage",
      });
    },
  });

  const rerunReportMutation = useMutation({
    mutationFn: (reportId: string) => {
      return ReportV3Adapter.versions.createOne({ data: { id: reportId } });
    },
    onSuccess: (data) => {
      queryClient.removeQueries({
        queryKey: [
          Resources.REPORT,
          generateReportJobDetails?.id,
          generateReportJobDetails?.version,
        ],
      });
      setSearchParams({
        version: data.data.latest_version.version,
      });
      setGenerateReportJobDetails({
        id: data.data.id,
        version: data.data.latest_version.version,
        isRegenerated: true,
      });
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    },
    onError: (error) => {
      toast.push({
        intent: "error",
        description: "Failed to re-run report.",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "TemplatedReportsGeneratedReportDetailsPage",
      });
    },
  });

  const downloadReportMutation = useMutation({
    mutationFn: async ({
      report_id,
      version,
    }: Pick<ReportVersionV3Type, "report_id" | "version">) => {
      const { data: reportVersionData } = await ReportV3Adapter.versions.getOne(
        {
          id: report_id,
          meta: { version },
        }
      );

      if (!reportVersionData.s3_download_link) {
        throw new BaseError("Report download link does not exist.", {
          getExplanation: () =>
            "Failed to download report: download link does not exist.",
        });
      }

      toast.push({
        intent: "success",
        description: "Successfully exported report.",
      });
      downloadLink(reportVersionData.s3_download_link, reportVersionData.name);
    },
    onError: (error) => {
      toast.push({
        intent: "error",
        description: "Unable to export report.",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "TemplatedReportsGeneratedReportDetailsPage",
      });
    },
  });

  const {
    data: generatedReport,
    error: generatedReportError,
    isLoading: generatedReportIsLoading,
  } = useQuery({
    queryKey: [
      Resources.REPORT,
      generateReportJobDetails?.id,
      generateReportJobDetails?.version,
    ],
    queryFn: () => {
      if (!generateReportJobDetails?.id || !generateReportJobDetails.version)
        return;

      return ReportV3Adapter.versions.getOne({
        id: generateReportJobDetails.id,
        meta: { version: generateReportJobDetails?.version },
      });
    },
    enabled:
      !!generateReportJobDetails?.id && !!generateReportJobDetails?.version,
    refetchIntervalInBackground: true,
    refetchInterval: (data, query) => {
      if (query.state.dataUpdateCount > 100) {
        if (!generateReportJobDetails.error) {
          setGenerateReportJobDetails((prevState) => ({
            ...prevState,
            error: "Report stuck in 'exporting' state",
          }));
          // the template details page keeps the exact same job in this queue - remove it
          window.localStorage.removeItem(
            getStorageKey(
              getGeneratedReportQueueStorageKey(templatedReportName ?? ""),
              "report",
              company?.id
            )
          );
        }
        return false;
      }

      return data?.data.version?.status &&
        !terminalGeneratedReportStatuses.includes(data.data?.version?.status)
        ? query.state.dataUpdateCount > 5
          ? 3500
          : 1500
        : false;
    },
  });

  const pageTitle = templateDetails?.is_embedded
    ? reportVersionDetailsQuery.data?.name
    : defaultReportDetailsQuery.data?.name;
  const [breadcrumbs, isBreadcrumbsLoading] = useBreadcrumbsFromRoute(
    TemplatedReportsGeneratedReportDetailsRoutePath,
    {
      "/:reportId": {
        title: pageTitle,
      },
      "/generated": {
        path: TemplatedReportsGeneratedReportDetailsRoutePath.previous?.previous
          ?.path,
      },
      "/:templateName": {
        title: templateDetails?.display_name,
      },
    }
  );
  const metadataUsersQueries = useGetManyUsers(
    Array.from(
      new Set(
        reportVersions?.data.versions.map(({ created_by }) => created_by ?? "")
      )
    )
  );

  const deleteReportMutation = useMutation({
    mutationFn: async (
      params: Parameters<typeof ReportV3Adapter.deleteOne>[0]
    ) => {
      const result = await ReportV3Adapter.deleteOne(params);

      if (!isResponseErrorType(result)) return result;

      throw new BaseError(`Failed to delete report ${params.id}`, {
        errors: [result.error],
      });
    },
    onSuccess: (_, variables) => {
      toast.push({
        intent: "success",
        description: `Successfully deleted report${variables.previousData?.name ? ` ${variables.previousData.name}.` : "."}`,
      });
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
      navigate(
        TemplatedReportsTemplateDetailRoutePath.toLinkParts({
          pathParams: {
            templateName:
              variables.previousData?.templated_report?.name ??
              templateDetails?.name,
          },
        })
      );
    },
    onError: (error, variables) => {
      toast.push({
        intent: "error",
        description: `Failed to delete report${variables.previousData?.name ? ` ${variables.previousData.name}.` : "."}`,
      });
      ExceptionUtils.reportException(error, "error", {
        hint: `Failed to delete report ${variables.id}`,
      });
    },
  });

  const { data: reportCommentsData } = useGetOneCommentFeed(
    {
      id: reportId,
      meta: {
        entity_type: CommentEntity.REPORT,
        additional_identifier: "",
        created_before: "",
      },
    },
    {
      enabled: Boolean(reportId) && canReadComments && canReadReports,
    }
  );

  const reportComments = useMemo(
    () =>
      reportCommentsData?.data
        .filter(
          (reportComment) =>
            !reportComment.is_deleted && !reportComment.additional_identifier
        )
        // The API sends the comments in descending order by commented_at
        .reverse() ?? [],
    [reportCommentsData]
  );

  const rowsWithComments = useMemo(() => {
    if (!reportRows || !reportCommentsData?.data) return [];

    const commentsByRow = reportCommentsData.data.reduce(
      (comments, comment) => {
        if (comment.is_deleted || !comment.additional_identifier) {
          return comments;
        }

        comments[comment.additional_identifier] =
          comments[comment.additional_identifier] || [];
        comments[comment.additional_identifier].push(comment);

        return comments;
      },
      {} as Record<string, CommentSchemaType[]>
    );

    return reportRows.map((row, i) => ({
      id: row.row_id,
      name: row.cells.header1?.value ?? `Row ${i}`,
      comments: (commentsByRow[row.row_id] || []).reverse(),
    }));
  }, [reportRows, reportCommentsData]);

  const rowCommentsCount = useMemo(
    () =>
      rowsWithComments.reduce(
        (totalComments, row) => totalComments + row.comments.length,
        0
      ),
    [rowsWithComments]
  );

  const getGeneratingReportBannerProps = (
    generatingReport: ReportWithVersionV3Type,
    templateName?: string,
    companyId?: string,
    error?: unknown,
    isEmbedded = false,
    isRegenerated = false
  ): Pick<
    BannerProps,
    | "titleText"
    | "descriptionText"
    | "variant"
    | "color"
    | "isDismissable"
    | "onDismiss"
    | "actionContent"
  > => {
    if (error) {
      return {
        titleText: isRegenerated
          ? "Failed to re-run the report."
          : "Failed to run the report.",
        descriptionText:
          error instanceof BaseError
            ? error.getExplanation()
            : error instanceof Error
              ? error.message
              : String(error),
        color: "error",
        variant: "error",
        isDismissable: true,
        actionContent: generatingReport?.id ? (
          <>
            {isEmbedded
              ? "Failed at "
              : "You can view the failed run below at "}
            {
              <DateDataDisplay
                value={generatingReport.version.created_at}
                displayFormat={DateFormats.DATE_TIME}
                isInline
              />
            }
          </>
        ) : (
          <></>
        ),
        onDismiss: () => {
          queryClient.removeQueries({
            queryKey: [
              Resources.REPORT,
              generateReportJobDetails.id,
              generateReportJobDetails.version,
            ],
          });
          setGenerateReportJobDetails({});
          // the template details page keeps the exact same job in this queue - remove it too
          window.localStorage.removeItem(
            getStorageKey(
              getGeneratedReportQueueStorageKey(templatedReportName ?? ""),
              "report",
              company?.id
            )
          );
          queryClient.invalidateQueries({
            queryKey: [Resources.REPORT, generateReportJobDetails.id],
          });
        },
      };
    }

    switch (generatingReport.version.status) {
      case "exporting":
        return {
          titleText: isRegenerated
            ? "Re-running report..."
            : "Running report...",
          color: "loading",
          variant: "loading",
        };
      case "success":
        return {
          titleText: isRegenerated
            ? "Successfully re-ran the report."
            : "Successfully ran the report.",
          color: "success",
          variant: "success",
          isDismissable: true,
          actionContent: (
            <>
              {isEmbedded ? "Completed at " : "See the completed run below at "}
              {
                <DateDataDisplay
                  value={generatingReport.version.created_at}
                  displayFormat={DateFormats.DATE_TIME}
                  isInline
                />
              }
            </>
          ),
          onDismiss: () => {
            queryClient.removeQueries({
              queryKey: [
                Resources.REPORT,
                generateReportJobDetails.id,
                generateReportJobDetails.version,
              ],
            });
            setGenerateReportJobDetails({});
            // the template details page keeps the exact same job in this queue - remove it too
            if (templateName && companyId)
              window.localStorage.removeItem(
                getStorageKey(
                  getGeneratedReportQueueStorageKey(templateName),
                  "report",
                  companyId
                )
              );
            queryClient.invalidateQueries({
              queryKey: [Resources.REPORT, generateReportJobDetails.id],
            });
          },
        };
      case "failed":
        return {
          titleText: isRegenerated
            ? "Failed to re-run the report."
            : "Failed to run the report.",
          color: "error",
          variant: "error",
          isDismissable: true,
          actionContent: (
            <>
              {isEmbedded
                ? "Failed at "
                : "You can view the failed run below at "}
              {
                <DateDataDisplay
                  value={generatingReport.version.created_at}
                  displayFormat={DateFormats.DATE_TIME}
                  isInline
                />
              }
            </>
          ),
          onDismiss: () => {
            queryClient.removeQueries({
              queryKey: [
                Resources.REPORT,
                generateReportJobDetails.id,
                generateReportJobDetails.version,
              ],
            });
            setGenerateReportJobDetails({});
            // the template details page keeps the exact same job in this queue - remove it too
            if (templateName && companyId)
              window.localStorage.removeItem(
                getStorageKey(
                  getGeneratedReportQueueStorageKey(templateName),
                  "report",
                  companyId
                )
              );
            queryClient.invalidateQueries({
              queryKey: [Resources.REPORT, generateReportJobDetails.id],
            });
          },
        };
    }
  };

  const navigateToReportVersion = (reportVersion: number) => {
    setSearchParams({
      version: !isNaN(reportVersion)
        ? reportVersion
        : latestReportVersion?.version,
    });
  };

  useEffect(() => {
    if (!generatedReport?.data) return;

    const status = generatedReport.data.version?.status;

    if (
      status === ReportGeneratedStatus.SUCCESS ||
      status === ReportGeneratedStatus.FAILED ||
      generatedReportError
    ) {
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    }
  }, [generatedReport?.data?.version?.status, generatedReportError]);

  useEffect(() => {
    if (commentsEndRef.current && shouldScrollToBottom) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [reportComments, shouldScrollToBottom, commentsEndRef]);

  useEffect(() => {
    setCommentCounts(
      `reportComments-${reportId}`,
      reportComments.length + rowCommentsCount
    );
  }, [reportComments.length, rowCommentsCount]);

  useEffect(() => {
    setIsTimelinePanelOpen(false);
    setActivePanelContent(null);
  }, []);

  const inputs = useMemo<
    Array<TemplatedReportInputType & { value: ReportInputValueType }>
  >(
    () =>
      templateDetails?.input_schema
        ? Object.entries(templateDetails?.input_schema).map(
            ([inputKey, inputConfig]) => ({
              ...inputConfig,
              value:
                reportVersionDetailsQuery.data?.input[inputKey] ??
                defaultReportDetailsQuery.data?.input[inputKey] ??
                "",
            })
          )
        : [],
    [templateDetails, defaultReportDetailsQuery.data?.input]
  );
  const metadataUsersMap = useMemo(
    () =>
      metadataUsersQueries?.reduce<Record<string, UserType>>((map, query) => {
        if (!query.data) {
          return map;
        }

        map[query.data.id] = query.data;
        return map;
      }, {}) ?? {},
    [metadataUsersQueries]
  );
  const reportStatusPillPopoverOptions: PillPopoverType<ReportStatusV3Type>["options"] =
    Object.entries(ReportStatusV3).map(([_, value]) => {
      return {
        label: toStartCaseString(value),
        value,
        icon: (
          {
            locked: "lock",
            unlocked: null,
            archived: null,
          } satisfies Record<ReportStatusV3Type, IconVariants | null>
        )[value],
        variant: (
          {
            locked: "default",
            unlocked: "info",
            archived: "warning",
          } satisfies Record<
            ReportStatusV3Type,
            PillPopoverOptionType<ReportStatusV3Type>["variant"]
          >
        )[value],
      };
    });
  const runHistoryTableHeaders: Array<HeaderType<ReportVersionV3Type>> = [
    {
      key: "created_at",
      label: "Ran",
      isSortable: true,
      renderComponent: ({ item }) => {
        return (
          <DataTable.DataRow.DateCell
            value={item.created_at}
            withTime
          />
        );
      },
    },
    {
      key: "created_by",
      label: "Ran By",
      isSortable: true,
      renderComponent: ({ item }) => {
        return item.created_by && metadataUsersMap[item.created_by]?.name ? (
          <>
            <Chip
              text={getNameAbbreviation(metadataUsersMap[item.created_by].name)}
              tooltipText={metadataUsersMap[item.created_by].name}
              style={{ marginRight: 4, minWidth: 26 }}
            />
            <RoutingLink to={linkToUserDetailPage(item.created_by)}>
              {metadataUsersMap[item.created_by].name}
            </RoutingLink>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => {
        return (
          <Pill
            variant={
              (
                {
                  exporting: "info",
                  failed: "error",
                  success: "success",
                } satisfies Record<
                  ReportVersionV3Type["status"],
                  PillProps["variant"]
                >
              )[item.status]
            }
          >
            {item.status}
          </Pill>
        );
      },
    },
  ];
  const embeddedReportDetailsTableHeaders: Array<
    HeaderType<ReportEmbeddedRowType>
  > = embeddedReportSheetDetailsQuery.data?.data?.[0]?.headers
    ? Object.entries(embeddedReportSheetDetailsQuery.data?.data?.[0]?.headers)
        .sort(([__, headerA], [_, headerB]) => {
          if (headerA.position > headerB.position) {
            return 1;
          } else if (headerA.position < headerB.position) {
            return -1;
          } else {
            return 0;
          }
        })
        .map(([headerKey, headerDetails]) => {
          const associatedMeasurementUnit =
            headerDetails.measurement_unit !== undefined &&
            headerDetails.measurement_unit !== null
              ? measurementUnits.find(
                  (unit) => unit.id === headerDetails.measurement_unit
                )
              : null;

          return {
            key: `${headerKey}.value`,
            label:
              associatedMeasurementUnit !== null
                ? `${headerDetails.display_name} [${associatedMeasurementUnit?.name.symbol}]`
                : headerDetails.display_name,
            isSortable: true,
            renderComponent: ({ item }: { item: ReportEmbeddedRowType }) => {
              return displayValueWithUnknownDataType(
                item.cells[headerKey].value,
                "-"
              );
            },
            alignment: associatedMeasurementUnit ? "right" : "left",
            getStyle: ({ item }: { item: ReportEmbeddedRowType }) => {
              const supportedStylesMapping: Record<
                | ReportEmbeddedStyleVariantType
                | `${ReportEmbeddedStyleVariantType}_dark`
                | `${ReportEmbeddedStyleVariantType}_light`,
                string
              > = {
                success_dark: "#18824F",
                success: "#26B474",
                success_light: "#ECFCF3",
                warning_dark: "#E37006",
                warning: "#FF8F27",
                warning_light: "#FFEEDF",
                error_dark: "#AF3032",
                error: "#D83B3E",
                error_light: "#FFE4E5",
                default_dark: "#3179E4",
                default: "#3179E4",
                default_light: "#E0EEFB",
              };

              if (!item.cells[headerKey].style) return {};

              return {
                backgroundColor:
                  supportedStylesMapping[
                    `${item.cells[headerKey].style}_light`
                  ],
                color:
                  supportedStylesMapping[`${item.cells[headerKey].style}_dark`],
                fontWeight: "bold",
              };
            },
          };
        })
    : [];

  const latestReportVersion = reportVersions?.data.versions.find(
    (version) => version.is_current
  );
  const status = templateDetails?.is_embedded
    ? reportVersionDetailsQuery.data?.status
    : defaultReportDetailsQuery.data?.status;
  const reportDetailsIsLoading = templateDetails?.is_embedded
    ? reportVersionDetailsQuery.isLoading
    : defaultReportDetailsQuery.isLoading;
  const isViewingOldVersion =
    templateDetails?.is_embedded &&
    !reportVersionDetailsQuery.isLoading &&
    latestReportVersion &&
    searchParams.version &&
    searchParams.version !== String(latestReportVersion?.version);
  const isGenerating =
    !isViewingOldVersion &&
    ((!!generateReportJobDetails?.id &&
      generatedReport?.data.version.status === "exporting") ||
      defaultReportDetailsQuery.data?.latest_version.status === "exporting");

  const embeddedReportErrorDetails = useMemo<
    | {
        message: string;
        cause: string;
      }
    | null
    | undefined
  >(() => {
    if (
      embeddedReportRowDetailsQuery.isLoading ||
      !embeddedReportRowDetailsQuery.isError
    )
      return;

    if (
      generatedReportError &&
      generatedReportError instanceof HTTPResponseError &&
      isResponseErrorType(generatedReportError)
    ) {
      switch (generatedReportError.status) {
        default:
          return {
            message: "This report cannot be shown at this time.",
            cause: "Invalid Run",
          };
      }
    }

    if (generateReportJobDetails?.error !== undefined) {
      return {
        message: generateReportJobDetails?.error,
        cause: "Invalid Run",
      };
    }

    if (
      embeddedReportRowDetailsQuery.error instanceof HTTPResponseError &&
      isResponseErrorType(
        embeddedReportRowDetailsQuery.error.responseBodyJson
      ) &&
      // the first fetch of the newly generated version's rows will fail since
      // it happens immediately. this is a false positive.
      ((generateReportJobDetails?.id &&
        embeddedReportRowDetailsQuery.errorUpdateCount > 1) ||
        !generateReportJobDetails?.id)
    ) {
      switch (embeddedReportRowDetailsQuery.error.status) {
        case 409:
          return {
            message: "This report version failed to complete running.",
            cause: "Failed Run",
          };
        default:
          return {
            message: "This report version cannot be shown at this time.",
            cause: "Invalid Run",
          };
      }
    }

    return null;
  }, [
    embeddedReportRowDetailsQuery.error,
    generatedReportError,
    generateReportJobDetails?.error,
    isGenerating,
  ]);
  const activeRowId = Object.keys(selectedRow)[0];
  const activeRowIndex = rowsWithComments.findIndex(
    (row) => row.id === activeRowId
  );
  const canCreateComments = canWriteComments && canWriteReports;
  const hasRowComments = rowsWithComments.some(
    (rowWithComments) => rowWithComments.comments.length !== 0
  );

  return (
    <>
      <Page
        title={
          pageTitle ? (
            <>
              {pageTitle}&nbsp;
              {status && defaultReportDetailsQuery.data?.id ? (
                <PillPopover
                  options={reportStatusPillPopoverOptions}
                  value={status}
                  isEditable
                  isBusy={changeReportStatusMutation.isLoading}
                  onChange={(newStatus: ReportStatusV3Type) =>
                    changeReportStatusMutation.mutate({
                      id: defaultReportDetailsQuery.data!.id,
                      data: {
                        status: newStatus,
                      },
                    })
                  }
                />
              ) : null}
            </>
          ) : (
            TemplatedReportsGeneratedReportDetailsRoutePath.title
          )
        }
        category={
          TemplatedReportsGeneratedReportDetailsRoutePath.previous?.title
        }
        breadcrumbs={breadcrumbs}
        isLoading={
          isBreadcrumbsLoading ||
          isTemplateDetailsLoading ||
          reportDetailsIsLoading
        }
        renderMeta={({ MetaSegments }) =>
          runInformation.description ? (
            <MetaSegments values={[runInformation.description]} />
          ) : null
        }
        actionRow={[
          ...(templateDetails?.is_embedded
            ? [
                <TimelinePanel.Trigger
                  key="versions-trigger"
                  targetDrawer={REPORT_DRAWER_KEY}
                >
                  {({ onClick }) => (
                    <Button
                      key="versions-button"
                      className={cx("timeline-panel-button", {
                        "timeline-panel-button__active":
                          activePanelContent === PanelContentType.VERSIONS,
                      })}
                      variant="outline"
                      slotLeft={
                        <Icon
                          variant="clock-counter-clockwise"
                          size={19}
                        />
                      }
                      slotRight={
                        <Pill variant="default">
                          {reportVersions?.data.versions.length}
                        </Pill>
                      }
                      onClick={() => {
                        if (activePanelContent === PanelContentType.VERSIONS) {
                          setActivePanelContent(null);
                          onClick?.();
                        } else {
                          setActivePanelContent(PanelContentType.VERSIONS);
                          if (!isTimelinePanelOpen) {
                            onClick?.();
                          }
                        }

                        setSelectedRow({});
                      }}
                    >
                      Versions
                    </Button>
                  )}
                </TimelinePanel.Trigger>,
              ]
            : []),
          ...(canReadComments && canReadReports && templateDetails?.is_embedded
            ? [
                <TimelinePanel.Trigger
                  key="comments-trigger"
                  targetDrawer={REPORT_DRAWER_KEY}
                >
                  {({ onClick }) => (
                    <Button
                      key="comments-button"
                      className={cx("timeline-panel-button", {
                        "timeline-panel-button__active":
                          activePanelContent ===
                          PanelContentType.REPORT_COMMENTS,
                      })}
                      variant="outline"
                      slotLeft={
                        <Icon
                          variant="chat-text"
                          size={19}
                        />
                      }
                      slotRight={
                        <Pill variant="default">
                          {commentCounts[`reportComments-${reportId}`]}
                        </Pill>
                      }
                      onClick={() => {
                        if (
                          activePanelContent ===
                          PanelContentType.REPORT_COMMENTS
                        ) {
                          setActivePanelContent(null);
                          onClick?.();
                        } else {
                          setActivePanelContent(
                            PanelContentType.REPORT_COMMENTS
                          );
                          if (!isTimelinePanelOpen) {
                            onClick?.();
                          }
                        }
                        setSelectedRow({});
                      }}
                    >
                      Comments
                    </Button>
                  )}
                </TimelinePanel.Trigger>,
              ]
            : []),
          <Button
            key="run"
            variant="primary"
            disabled={
              status !== "unlocked" ||
              !reportId ||
              !!generateReportJobDetails.id
            }
            isLoading={rerunReportMutation.isLoading}
            onClick={() => {
              if (!reportId) return;

              rerunReportMutation.mutate(reportId);
            }}
          >
            Re-Run
          </Button>,
          <DropdownMenu
            key="more"
            options={[
              {
                label: "View Details",
                slotLeft: ({ iconProps }) => (
                  <Icon
                    {...iconProps}
                    variant="eye"
                  />
                ),
                onClick: () => setIsDetailsDrawerOpen(true),
              },
              {
                isSeparator: true,
              },
              {
                label: "Delete",
                variant: "danger",
                slotLeft: ({ iconProps }) => (
                  <Icon
                    {...iconProps}
                    variant="trash"
                  />
                ),
                isDisabled:
                  !defaultReportDetailsQuery.data || status === "locked",
                onClick: () => {
                  if (!defaultReportDetailsQuery.data) return;

                  setReportToDelete(defaultReportDetailsQuery.data);
                },
              },
            ]}
          >
            <Button
              icon="dots-three-vertical"
              variant="outline"
            />
          </DropdownMenu>,
        ]}
        {...{
          rightSlot: (
            <>
              <TimelinePanel.Drawer
                className={cx("timeline-panel")}
                shouldRenderPortal={false}
                drawerKey={REPORT_DRAWER_KEY}
                onClose={() => {
                  setActivePanelContent(null);
                  setSelectedRow({});
                }}
              >
                <TimelinePanel.Header
                  title={
                    activePanelContent === PanelContentType.VERSIONS
                      ? "Versions"
                      : activePanelContent === PanelContentType.REPORT_COMMENTS
                        ? "Comments"
                        : "Row Comments"
                  }
                  className={cx("timeline-panel__header")}
                />
                <TimelinePanel.Content
                  isLoading={isReportVersionsLoading}
                  className={cx(
                    "timeline-panel__content",

                    (activePanelContent === PanelContentType.REPORT_COMMENTS ||
                      activePanelContent === PanelContentType.ROW_COMMENTS) && {
                      "comment-panel-content": true,
                      "no-overflow":
                        (commentPanelActiveKey === "report" &&
                          !reportComments?.length) ||
                        (commentPanelActiveKey === "row" && !hasRowComments),
                    }
                  )}
                >
                  {activePanelContent === PanelContentType.VERSIONS ? (
                    <ol className={cx("timeline-panel__list")}>
                      {reportVersions?.data.versions
                        .sort((reportA, reportB) => {
                          if (reportA.version > reportB.version) {
                            return -1;
                          } else if (reportA.version < reportB.version) {
                            return 1;
                          } else {
                            return 0;
                          }
                        })
                        .map((v) => (
                          <ListCard
                            key={v.version}
                            renderTitle={({ FieldSegment }) => (
                              <FieldSegment>
                                v{v.version}
                                {v.version ===
                                defaultReportDetailsQuery.data?.latest_version
                                  .version
                                  ? " (Current)"
                                  : ""}
                              </FieldSegment>
                            )}
                            renderMeta={({ FieldSegment }) => (
                              <FieldSegment>
                                Ran on{" "}
                                <DateDataDisplay
                                  value={v.created_at}
                                  isInline
                                />
                                {metadataUsersMap
                                  ? ` by ${metadataUsersMap[v.created_by]?.name}`
                                  : ""}
                              </FieldSegment>
                            )}
                            colorVariant="default"
                            isSelected={v.version === currentReportVersion}
                            as="li"
                            hasBorderBottom
                            onClick={() => {
                              setSearchParams({
                                version: v.version,
                              });
                            }}
                          />
                        )) ?? null}
                    </ol>
                  ) : activePanelContent ===
                    PanelContentType.REPORT_COMMENTS ? (
                    <>
                      <div
                        className={cx(
                          "comment-panel-button-toggle-group-container"
                        )}
                      >
                        <ButtonToggleGroup
                          className={cx("comment-panel-button-toggle-group")}
                          items={[
                            {
                              label: "Report",
                              dataKey: "report",
                              rightSlot: reportComments && (
                                <Pill variant="default">
                                  {reportComments.length}
                                </Pill>
                              ),
                            },
                            {
                              label: "Row",
                              dataKey: "row",
                              rightSlot: reportComments && (
                                <Pill variant="default">
                                  {rowCommentsCount}
                                </Pill>
                              ),
                            },
                          ]}
                          activeKey={commentPanelActiveKey ?? "report"}
                          onChange={(newActiveKey) =>
                            setCommentPanelActiveKey(newActiveKey)
                          }
                        />
                      </div>

                      {commentPanelActiveKey === "report" ? (
                        <>
                          {reportComments && reportComments.length > 0 ? (
                            <>
                              {reportComments.map((reportComment) => (
                                <Message
                                  className={cx(
                                    "comment",
                                    reportComment.id ===
                                      newlyCreatedCommentId &&
                                      "play-flash-animation"
                                  )}
                                  key={reportComment.id}
                                  name={reportComment.user?.name}
                                  meta={`${reportComment.title_context} • ${datetimeFormatter(
                                    new Date(reportComment.commented_at ?? "")
                                  )}`}
                                  textContent={reportComment.text}
                                  {...(canDeleteCommentsAsAdmin ||
                                  (canWriteComments &&
                                    user?.id === reportComment.user?.id)
                                    ? {
                                        actions: [
                                          {
                                            label: "Delete",
                                            onClick: () => {
                                              setShowDeleteCommentDialog(true);
                                              setCommentIdToDelete(
                                                reportComment.id
                                              );
                                            },
                                            slotLeft: () => (
                                              <Icon variant="trash" />
                                            ),
                                            variant: "danger",
                                          },
                                        ],
                                      }
                                    : undefined)}
                                  onClick={() => {
                                    navigateToReportVersion(
                                      Number(
                                        reportComment.title_context?.slice(1)
                                      )
                                    );
                                  }}
                                />
                              ))}
                              <div ref={commentsEndRef} />
                            </>
                          ) : (
                            <EmptyState
                              className={cx("comments-empty-state")}
                              title="No comments yet."
                              icon={<Icon variant="chat-text" />}
                            />
                          )}
                        </>
                      ) : null}
                      {commentPanelActiveKey === "row" ? (
                        rowsWithComments &&
                        rowsWithComments.length > 0 &&
                        hasRowComments ? (
                          rowsWithComments.map((row) =>
                            row.comments.length > 0 ? (
                              <Accordion
                                key={row.id}
                                defaultActiveKeys={[row.id]}
                              >
                                <AccordionPanel
                                  key={row.id}
                                  dataKey={row.id}
                                  title={
                                    <div>
                                      <p
                                        className={cx(
                                          "comment-panel-row-title"
                                        )}
                                      >
                                        {row.name}
                                      </p>
                                    </div>
                                  }
                                  className={cx("comment-panel-row-accordion")}
                                  iconVariant="triangle-down"
                                  iconProps={{ size: 12 }}
                                >
                                  <>
                                    {row.comments.map(
                                      (rowComment: CommentSchemaType) => (
                                        <Message
                                          className={cx(
                                            "comment",
                                            rowComment.id ===
                                              newlyCreatedCommentId &&
                                              "play-flash-animation"
                                          )}
                                          key={rowComment.id}
                                          name={rowComment.user?.name}
                                          meta={`${rowComment.title_context} • ${datetimeFormatter(
                                            new Date(
                                              rowComment.commented_at ?? ""
                                            )
                                          )}`}
                                          textContent={rowComment.text}
                                          {...(canDeleteCommentsAsAdmin ||
                                          (canWriteComments &&
                                            user?.id === rowComment.user?.id)
                                            ? {
                                                actions: [
                                                  {
                                                    label: "Delete",
                                                    onClick: () => {
                                                      setShowDeleteCommentDialog(
                                                        true
                                                      );
                                                      setCommentIdToDelete(
                                                        rowComment.id
                                                      );
                                                    },
                                                    slotLeft: () => (
                                                      <Icon variant="trash" />
                                                    ),
                                                    variant: "danger",
                                                  },
                                                ],
                                              }
                                            : undefined)}
                                          onClick={() => {
                                            navigateToReportVersion(
                                              Number(
                                                rowComment.title_context?.slice(
                                                  1
                                                )
                                              )
                                            );
                                          }}
                                        />
                                      )
                                    )}
                                    {canCreateComments ? (
                                      <div
                                        className={cx(
                                          "comment-panel-row-comment-form-container"
                                        )}
                                      >
                                        <CommentForm
                                          entity_type={CommentEntity.REPORT}
                                          entity_id={reportId}
                                          additional_identifier={row.id}
                                          title_context={`V${searchParams.version}`}
                                          onCreateCallback={handleCreateComment}
                                          canCreateComments={canCreateComments}
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                </AccordionPanel>
                              </Accordion>
                            ) : null
                          )
                        ) : (
                          <EmptyState
                            className={cx("comments-empty-state")}
                            title="No comments yet."
                            icon={<Icon variant="chat-text" />}
                          />
                        )
                      ) : null}
                    </>
                  ) : activePanelContent === PanelContentType.ROW_COMMENTS ? (
                    !isEmpty(selectedRow) &&
                    rowsWithComments[activeRowIndex]?.comments.length > 0 ? (
                      <>
                        {rowsWithComments[activeRowIndex].comments.map(
                          (rowComment: CommentSchemaType) => (
                            <Message
                              className={cx(
                                "comment",
                                rowComment.id === newlyCreatedCommentId &&
                                  "play-flash-animation"
                              )}
                              key={rowComment.id}
                              name={rowComment.user?.name}
                              meta={`${rowComment.title_context} • ${datetimeFormatter(
                                new Date(rowComment.commented_at ?? "")
                              )}`}
                              textContent={rowComment.text}
                              {...(canDeleteCommentsAsAdmin ||
                              (canWriteComments &&
                                user?.id === rowComment.user?.id)
                                ? {
                                    actions: [
                                      {
                                        label: "Delete",
                                        onClick: () => {
                                          setShowDeleteCommentDialog(true);
                                          setCommentIdToDelete(rowComment.id);
                                        },
                                        slotLeft: () => (
                                          <Icon variant="trash" />
                                        ),
                                        variant: "danger",
                                      },
                                    ],
                                  }
                                : undefined)}
                              onClick={() => {
                                navigateToReportVersion(
                                  Number(rowComment.title_context?.slice(1))
                                );
                              }}
                            />
                          )
                        )}
                        <div ref={commentsEndRef} />
                      </>
                    ) : (
                      <EmptyState
                        className={cx("comments-empty-state")}
                        title="No comments yet."
                        icon={<Icon variant="chat-text" />}
                      />
                    )
                  ) : null}
                </TimelinePanel.Content>
                {((activePanelContent === PanelContentType.REPORT_COMMENTS &&
                  commentPanelActiveKey === "report") ||
                  activePanelContent === PanelContentType.ROW_COMMENTS) &&
                canCreateComments ? (
                  <TimelinePanel.Footer className={cx("comment-panel-footer")}>
                    <CommentForm
                      entity_type={CommentEntity.REPORT}
                      entity_id={reportId}
                      {...(activeRowId &&
                      activePanelContent === PanelContentType.ROW_COMMENTS
                        ? { additional_identifier: activeRowId }
                        : {})}
                      title_context={`V${searchParams.version}`}
                      onCreateCallback={handleCreateComment}
                      canCreateComments={canCreateComments}
                    />
                  </TimelinePanel.Footer>
                ) : null}
              </TimelinePanel.Drawer>
            </>
          ),
        }}
      >
        {isViewingOldVersion ? (
          <Banner
            className={cx("banner")}
            variant="generic"
            titleText={`You are viewing a previous version (v${currentReportVersion}) of this report.`}
            actionContent={
              <Button
                variant="text"
                type="button"
                disabled={!latestReportVersion}
                onClick={() => {
                  setSearchParams({
                    version: latestReportVersion?.version,
                  });
                }}
              >
                View Current
              </Button>
            }
          />
        ) : null}
        {generateReportJobDetails?.id &&
        !generatedReportIsLoading &&
        generatedReport?.data.version ? (
          <Banner
            className={cx("banner")}
            {...getGeneratingReportBannerProps(
              generatedReport?.data,
              templatedReportName,
              company?.id,
              generatedReportError ?? generateReportJobDetails.error,
              templateDetails?.is_embedded ?? false,
              generateReportJobDetails.isRegenerated
            )}
          />
        ) : null}
        <div className={cx("container")}>
          <PanelCollapsible
            title="Details"
            className={cx("details-panel")}
            isCollapsed
          >
            {inputs.length ? (
              <KeyValueTileGrid
                data={inputs.map((inp) => ({
                  title: inp.display_name,
                  value: displayValueByDataType(
                    inp.data_type === "lookup"
                      ? inp.lookup_entity_attribute
                        ? {
                            [inp.lookup_entity_attribute]: inp.value, // value will be the attribute value
                          }
                        : { id: inp.value } // assume it's a UUID otherwise
                      : inp.value,
                    inp.data_type
                  ),
                }))}
              />
            ) : (
              <EmptyState
                title="No Inputs Configured"
                suggestion="This report was run without input values."
                icon={<Icon variant="info" />}
              />
            )}
          </PanelCollapsible>
          {templateDetails?.is_embedded ? (
            !embeddedReportErrorDetails ? (
              <DataTablePanel
                storageKey={embeddedReportTableConfigStorageKey}
                panelProps={{
                  className: cx("data-panel"),
                  loaded:
                    !isGenerating &&
                    !embeddedReportRowDetailsQuery.isLoading &&
                    !embeddedReportSheetDetailsQuery.isLoading,
                  actionRow: [
                    <Button
                      key="export"
                      variant="outline"
                      icon="arrow-square-out"
                      disabled={!reportId}
                      isLoading={downloadReportMutation.isLoading}
                      onClick={() => {
                        if (!reportId) return;

                        downloadReportMutation.mutate({
                          report_id: reportId,
                          version: currentReportVersion,
                        });
                      }}
                    />,
                  ],
                  shouldShowTitleSubscript: false,
                }}
                dataTableProps={{
                  showRowNumbers: true,
                  ...(rowsWithComments.length > 0 && {
                    getRowAdornment: ({ item }) => {
                      const row = rowsWithComments.find(
                        (row) => row.id === item.row_id
                      );
                      const commentCount = row?.comments.length;

                      if (!commentCount) {
                        return null;
                      }

                      return {
                        text: `This row has ${commentCount} comment${commentCount === 1 ? "" : "s"}`,
                      };
                    },
                  }),
                  selected: selectedRow,
                  onSelectionChange: setSelectedRow,
                  getItemId: (item) => item.row_id,
                  isSingleSelection: true,
                  hideSelectionCheckboxes: true,
                  items: reportRows ?? [],
                  headers: embeddedReportDetailsTableHeaders ?? [],
                  isLoading:
                    embeddedReportRowDetailsQuery.isLoading ||
                    embeddedReportSheetDetailsQuery.isLoading,
                  isBusy: embeddedReportRowDetailsQuery.isFetching,
                  sorting: {
                    sortBy: embeddedRowsTableState.sortBy,
                    sortDirection: embeddedRowsTableState.sortDirection,
                  },
                  pagination: {
                    page: embeddedRowsTableState.page,
                    pageSize: embeddedRowsTableState.pageSize,
                    total:
                      embeddedReportRowDetailsQuery.data?.total_entries ??
                      embeddedRowsTableState.total ??
                      0,
                  },
                  onSortChange: (newState) => {
                    setIsTimelinePanelOpen(false);
                    setActivePanelContent(null);
                    setSelectedRow({});
                    updateEmbeddedRowsTableState(newState);
                  },
                  onPaginationChange: updateEmbeddedRowsTableState,
                  getItemActions: ({ item }) =>
                    canReadComments && canReadReports
                      ? [
                          {
                            label: `Comments`,
                            buttonProps: {
                              icon: "chat-text",
                              onClick: () => {
                                const isCurrentRow =
                                  item.row_id === activeRowId;

                                setSelectedRow(
                                  isCurrentRow ? {} : { [item.row_id]: item }
                                );
                                setActivePanelContent(
                                  isCurrentRow
                                    ? null
                                    : PanelContentType.ROW_COMMENTS
                                );
                                setIsTimelinePanelOpen(!isCurrentRow);
                                setTargetDrawer(REPORT_DRAWER_KEY);
                              },
                            },
                          },
                        ]
                      : [],
                }}
              />
            ) : (
              <Panel className={cx("data-panel")}>
                <EmptyState
                  variant="error"
                  icon={<Icon variant="warning-circle" />}
                  title={embeddedReportErrorDetails.cause}
                  suggestion={embeddedReportErrorDetails.message}
                />
              </Panel>
            )
          ) : (
            <DataTablePanel
              storageKey={runHistoryTableConfigStorageKey}
              panelProps={{
                title: "Run History",
                className: cx("data-panel"),
              }}
              dataTableProps={{
                items: reportVersionsForTable,
                headers: runHistoryTableHeaders,
                isLoading: isReportVersionsLoading,
                isBusy: isReportVersionsFetching,
                getItemActions: ({ item }) => {
                  return [
                    {
                      label: "Export",
                      buttonProps: {
                        icon: "arrow-square-out",
                        variant: "outline",
                        isLoading:
                          downloadReportMutation.variables?.report_id ===
                            item.report_id && downloadReportMutation.isLoading,
                        onClick: () => {
                          downloadReportMutation.mutate({
                            report_id: item.report_id,
                            version: item.version,
                          });
                        },
                      },
                    },
                  ];
                },
                sorting: reportVersionsSorting,
                pagination: {
                  ...reportVersionsPagination,
                  total:
                    runHistoryTableState.total ??
                    reportVersions?.data.versions.length ??
                    0,
                },
                onSortChange: updateRunHistoryTableState,
                onPaginationChange: updateRunHistoryTableState,
              }}
            />
          )}
        </div>
      </Page>
      <Drawer
        title="Report Details"
        isOpen={isDetailsDrawerOpen}
        onClose={() => setIsDetailsDrawerOpen(false)}
      >
        <KeyValueList
          isLoading={reportDetailsIsLoading}
          data={[
            {
              title: "Templated Report",
              value: defaultReportDetailsQuery.data ? (
                <RoutingLink
                  to={TemplatedReportsTemplateDetailRoutePath.toLinkParts({
                    pathParams: {
                      templateName:
                        defaultReportDetailsQuery.data.templated_report_name ??
                        defaultReportDetailsQuery.data.templated_report?.name,
                    },
                  })}
                >
                  {defaultReportDetailsQuery.data.templated_report
                    ?.display_name ??
                    defaultReportDetailsQuery.data.templated_report_name}
                </RoutingLink>
              ) : null,
            },
            {
              title: "Updated At",
              value: (
                <DateDataDisplay
                  value={defaultReportDetailsQuery.data?.updated_at}
                  displayFormat={DateFormats.DATE_TIME}
                />
              ),
            },
            {
              title: "Created At",
              value: (
                <DateDataDisplay
                  value={templateDetails?.created_at}
                  displayFormat={DateFormats.DATE_TIME}
                />
              ),
            },
          ]}
        />
      </Drawer>
      <Dialog
        title="Delete Report?"
        isOpen={reportToDelete !== null}
        onClose={() => setReportToDelete(null)}
        actionRow={[
          <Button
            key="submit"
            variant="error"
            isLoading={deleteReportMutation.isLoading}
            onClick={() => {
              if (!reportToDelete) return;

              deleteReportMutation
                .mutateAsync({
                  id: reportToDelete.id,
                  previousData: reportToDelete,
                })
                .finally(() => setReportToDelete(null));
            }}
          >
            Delete
          </Button>,
        ]}
      >
        Deleting the report will delete{" "}
        {templateDetails?.is_embedded
          ? "all versions of this report"
          : "the entire run history and the report"}
        . This action cannot be undone.
      </Dialog>
      <DeleteCommentDialog
        isOpen={showDeleteCommentDialog}
        onClose={() => {
          setShowDeleteCommentDialog(false);
        }}
        commentIdToDelete={commentIdToDelete ?? ""}
        handleDelete={handleDeleteComment}
      />
    </>
  );
};
