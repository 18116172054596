import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  FilterArea,
  useFilterAreaContentContext,
  useFilterAreaContext,
} from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { TemplatedReportsTemplateDetailRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]";
import { TemplatedReportsGeneratedReportDetailsRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]/generated/[reportId]";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import {
  displayValueByDataType,
  displayValueWithUnknownDataType,
} from "#src/utils/display";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Chip,
  DataTable,
  DataTablePanel,
  type DataTableProps,
  DateSelectorInput,
  HeaderType,
  type IconVariants,
  PillPopover,
  type PillPopoverOptionType,
  type PillPopoverType,
  PillToggleGroup,
  type PillToggleProps,
  PillToggleVariants,
  Tag,
  TextInput,
  useToast,
} from "@validereinc/common-components";
import {
  BaseError,
  ReportStatusV3,
  type ReportStatusV3Type,
  ReportV3Adapter,
  ReportV3Schema,
  type ReportV3Type,
  type ReportVersionV3Type,
  Resources,
  SortDirection,
  type UserType,
} from "@validereinc/domain";
import {
  downloadLink,
  getNameAbbreviation,
  toStartCaseString,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import isPlainObject from "lodash/isPlainObject";
import startCase from "lodash/startCase";
import React, { useMemo } from "react";
import styles from "./TemplatedReportGeneratedReportsTablePanel.module.scss";

const cx = classNames.bind(styles);

export const TemplatedReportGeneratedReportsTablePanel = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const coreStorageKeys = useStorageKey(
    "templated-reports-all-generated-reports"
  );
  const quickFilterStorageKeys = useStorageKey(
    "templated-reports-all-generated-reports-quick-filters"
  );
  const [coreFilters] = useSessionStickyState(
    {},
    coreStorageKeys.filterConfigStorageKey
  );
  const [quickFilters] = useSessionStickyState(
    {},
    quickFilterStorageKeys.filterConfigStorageKey
  );
  const filters = useMemo(
    () => ({ ...coreFilters, ...quickFilters }),
    [coreFilters, quickFilters]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "latest_version.created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const getListQueryParams: Parameters<typeof ReportV3Adapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...(filters.search ? { name: { $like: filters.search } } : {}),
      ...(filters.status?.length && filters.status.every((s) => !!s)
        ? { status: { $in: filters.status } }
        : {}),
      ...(filters?.latest_version?.created_at
        ? {
            ["latest_version.created_at"]: {
              $and: [
                { $gte: filters?.latest_version?.created_at.from },
                { $lte: filters?.latest_version?.created_at.to },
              ],
            },
          }
        : {}),
      ...(filters?.latest_version?.created_by
        ? {
            ["latest_version.created_by"]: filters?.latest_version?.created_by,
          }
        : {}),
      // REVIEW: the endpoint needs the empty string explicitly to filter out
      // all reports except templated reports even when none are selected to
      // filter for. This is intentional here but perhaps the endpoint needs a
      // better design?
      templated_report_name: !coreFilters.templated_report_name?.length
        ? { $like: "" }
        : { $in: filters.templated_report_name },
      isAlreadyFormatted: true,
    },
  };
  const { data: allReports, isLoading: allReportsIsLoading } = useQuery({
    queryKey: [Resources.REPORT, getListQueryParams],
    queryFn: () => {
      return ReportV3Adapter.getList(getListQueryParams);
    },
    staleTime: 5 * 60 * 1000,
  });
  const allReportEntries = allReports?.data ?? [];

  const usersQuery = useGetManyUsers(
    Array.from(
      new Set(
        allReportEntries.flatMap(
          ({ created_by, updated_by, latest_version }) => [
            created_by,
            updated_by,
            latest_version.created_by,
          ]
        )
      )
    ).filter((item) => item)
  );
  const changeReportStatusMutation = useMutation({
    mutationFn: (params: Parameters<typeof ReportV3Adapter.updateOne>[0]) => {
      return ReportV3Adapter.updateOne(params);
    },
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: "Successfully changed templated report status.",
      });
      queryClient.invalidateQueries({ queryKey: [Resources.REPORT] });
    },
    onError: (error) => {
      toast.push({
        intent: "error",
        description: "Failed to change templated report status.",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "TemplatedReportGeneratedReportsTablePanel",
      });
    },
  });
  const downloadReportMutation = useMutation({
    mutationFn: async ({
      report_id,
      version,
    }: Pick<ReportVersionV3Type, "report_id" | "version">) => {
      const { data: reportVersionData } = await ReportV3Adapter.versions.getOne(
        {
          id: report_id,
          meta: { version },
        }
      );

      if (!reportVersionData.s3_download_link) {
        throw new BaseError("Report download link does not exist.", {
          getExplanation: () =>
            "Failed to download report: download link does not exist.",
        });
      }

      downloadLink(reportVersionData.s3_download_link, reportVersionData.name);
    },
    onError: (error) => {
      toast.push({
        intent: "error",
        description: "Failed to download report",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "TemplatedReportGeneratedReportsTablePanel",
      });
    },
  });

  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const headers: Array<HeaderType<ReportV3Type>> = [
    {
      label: "Name",
      key: "display_name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item.id && item.templated_report?.name ? (
          <RoutingLink
            to={TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts({
              pathParams: {
                reportId: item.id,
                templateName: item.templated_report?.name,
              },
              queryParams: {
                version: item.latest_version.version,
              },
            })}
          >
            {item.name}
          </RoutingLink>
        ) : (
          item.name
        ),
    },
    // IMPROVE: show a report version or applicable year here in the future for more context and tie-in with the exportable reports tab where the report is actually exported
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => {
        const reportStatusPillPopoverOptions: PillPopoverType<ReportStatusV3Type>["options"] =
          Object.entries(ReportStatusV3).map(([_, value]) => {
            return {
              label: toStartCaseString(value),
              value,
              icon: (
                {
                  locked: "lock",
                  unlocked: null,
                  archived: null,
                } satisfies Record<ReportStatusV3Type, IconVariants | null>
              )[value],
              variant: (
                {
                  locked: "default",
                  unlocked: "info",
                  archived: "warning",
                } satisfies Record<
                  ReportStatusV3Type,
                  PillPopoverOptionType<ReportStatusV3Type>["variant"]
                >
              )[value],
            };
          });

        return (
          <PillPopover
            options={reportStatusPillPopoverOptions}
            value={item.status}
            isEditable
            isBusy={
              item.id === changeReportStatusMutation.variables?.id &&
              changeReportStatusMutation.isLoading
            }
            onChange={(newStatus: ReportStatusV3Type) =>
              changeReportStatusMutation.mutate({
                id: item.id,
                data: {
                  status: newStatus,
                },
              })
            }
          />
        );
      },
    },
    {
      label: "Template",
      key: "templated_report.display_name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item.templated_report?.name ? (
          <RoutingLink
            to={TemplatedReportsTemplateDetailRoutePath.toLinkParts({
              pathParams: {
                templateName: item.templated_report?.name,
              },
            })}
          >
            {item.templated_report.display_name}
          </RoutingLink>
        ) : (
          item.templated_report_name
        ),
    },
    {
      label: "Inputs",
      key: "input",
      renderComponent: ({ item }) => {
        if (!isPlainObject(item.input)) return "unknown";

        const associatedInputSchema = item.templated_report?.input_schema;

        return (
          <ol className={cx("tag-container")}>
            {Object.entries(item.input).map(([k, v]) => {
              const displayLabel = associatedInputSchema
                ? associatedInputSchema[k]?.display_name ?? startCase(k)
                : startCase(k);

              return (
                <Tag
                  key={k}
                  as="li"
                  value={
                    associatedInputSchema?.[k]?.data_type
                      ? displayValueByDataType(
                          associatedInputSchema[k].data_type === "lookup"
                            ? associatedInputSchema[k].lookup_entity_attribute
                              ? {
                                  [associatedInputSchema[k]
                                    .lookup_entity_attribute]: v, // value will be the attribute value
                                }
                              : { id: v } // assume it's a UUID otherwise
                            : v,
                          associatedInputSchema[k].data_type
                        )
                      : displayValueWithUnknownDataType(v)
                  }
                  label={displayLabel || "Unknown"}
                />
              );
            })}
          </ol>
        );
      },
    },
    {
      label: "Last Updated At",
      key: "latest_version.created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.latest_version.created_at}
          withTime
        />
      ),
    },
    {
      label: "Last Updated By",
      key: "latest_version.created_by",
      isSortable: true,
      renderComponent: ({ item }) =>
        item.latest_version.created_by &&
        userMap[item.latest_version.created_by]?.name ? (
          <>
            <Chip
              text={getNameAbbreviation(
                userMap[item.latest_version.created_by].name
              )}
              tooltipText={userMap[item.latest_version.created_by].name}
              style={{ marginRight: 4, minWidth: 26 }}
            />
            <RoutingLink
              to={linkToUserDetailPage(item.latest_version.created_by)}
            >
              {userMap[item.latest_version.created_by].name}
            </RoutingLink>
          </>
        ) : (
          "-"
        ),
    },
  ];

  const getItemActions: DataTableProps<ReportV3Type>["getItemActions"] = ({
    item,
  }) => [
    {
      label: "Export latest version",
      buttonProps: {
        onClick: () => {
          downloadReportMutation.mutate({
            report_id: item.id,
            version: item.latest_version.version,
          });
        },
        isLoading: downloadReportMutation.isLoading,
        icon: "arrow-square-out",
      },
    },
  ];

  const StatusPillToggleGroup = ({ name }: { name: string }) => {
    const { handleOnChange } = useFilterAreaContentContext();
    const { storedFilters: { status } = {} } = useFilterAreaContext<{
      status: string[];
    }>();

    const pillToggles: PillToggleProps[] = [
      {
        name: "All",
        label: "All",
        value: "",
        isSelected: !status?.length || status?.includes(""),
        shouldSelectAll: true,
      },
      {
        name: ReportStatusV3.UNLOCKED,
        label: toStartCaseString(ReportStatusV3.UNLOCKED),
        value: ReportStatusV3.UNLOCKED,
        variant: PillToggleVariants.PENDING,
        isSelected: status?.includes(ReportStatusV3.UNLOCKED) ?? false,
      },
      {
        name: ReportStatusV3.LOCKED,
        label: toStartCaseString(ReportStatusV3.LOCKED),
        value: ReportStatusV3.LOCKED,
        variant: PillToggleVariants.NEUTRAL,
        isSelected: status?.includes(ReportStatusV3.LOCKED) ?? false,
      },
      {
        name: ReportStatusV3.ARCHIVED,
        label: toStartCaseString(ReportStatusV3.ARCHIVED),
        value: ReportStatusV3.ARCHIVED,
        variant: PillToggleVariants.ATTENTION,
        isSelected: status?.includes(ReportStatusV3.ARCHIVED) ?? false,
      },
    ];

    return (
      <PillToggleGroup
        name={name}
        pills={pillToggles}
        onChange={(val) => handleOnChange(val, name)}
      />
    );
  };

  const filterKeys = ReportV3Schema.keyof().Enum;

  return (
    <DataTablePanel
      storageKey={coreStorageKeys.tableConfigStorageKey}
      panelProps={{
        title: "All Reports",
        titleDecorator: (
          <FilterArea.Root
            storageKey={quickFilterStorageKeys.filterConfigStorageKey}
            defaultValues={{
              status: [ReportStatusV3.LOCKED, ReportStatusV3.UNLOCKED],
            }}
            shouldMergeWhenApplyingDefaultValues={false}
            applyDefaultValues
          >
            <FilterArea.Container aria-label="Status filter for generated reports">
              <FilterArea.Content>
                {() => <StatusPillToggleGroup name={filterKeys.status} />}
              </FilterArea.Content>
            </FilterArea.Container>
          </FilterArea.Root>
        ),
      }}
      filterComponent={
        <FilterArea.Root storageKey={coreStorageKeys.filterConfigStorageKey}>
          <FilterDrawer.Root>
            <FilterArea.Container aria-label="Filters for generated reports">
              <FilterArea.Content>
                {({ handleOnChange }) => (
                  <div style={{ marginRight: 8, marginBottom: 0 }}>
                    <TextInput
                      name="search"
                      type="search"
                      label="Search"
                      placeholder="Search by Name..."
                      isLabelShown={false}
                      isInline
                      onChange={(val) => handleOnChange(val, "search")}
                    />
                  </div>
                )}
              </FilterArea.Content>
              <FilterDrawer.Trigger />
              <FilterDrawer.Content>
                <DateSelectorInput
                  // REVIEW: can be accurately mapped to root property updated_at when bug is fixed https://validere.atlassian.net/browse/CHB-5596
                  name={`latest_version.${filterKeys.created_at}`}
                  label="Last Updated At"
                  placeholder="Select a date..."
                  isRange
                  variant="time"
                  isFluid
                  isInline={false}
                />
                <UsersDropdownInput
                  // REVIEW: can be accurately mapped to root property updated_by when bug is fixed https://validere.atlassian.net/browse/CHB-5596
                  name={`latest_version.${filterKeys.created_by}`}
                  label="Last Updated By"
                  placeholder="Select a User..."
                />
              </FilterDrawer.Content>
            </FilterArea.Container>
          </FilterDrawer.Root>
        </FilterArea.Root>
      }
      dataTableProps={{
        variant: "simplicity-first",
        isLoading:
          allReportsIsLoading || usersQuery.some(({ isLoading }) => isLoading),
        items: allReportEntries ?? [],
        headers,
        getItemActions,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: tableState.total ?? allReports?.total_entries ?? 0,
        },
      }}
    />
  );
};
