import { RoleListTab } from "#src/batteries-included-components";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { useIsOpsHubExperience } from "#src/contexts/AuthenticatedContext.helpers";
import { Page } from "@validereinc/common-components";
import React from "react";
import { ROLES_AND_PERMISSIONS_BREADCRUMB } from ".";
import { SETTINGS_BREADCRUMB } from "..";

export const RolesAndPermissionsPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return (
    <Page
      title="Roles & Permissions"
      breadcrumbs={[SETTINGS_BREADCRUMB, ROLES_AND_PERMISSIONS_BREADCRUMB]}
    >
      <RoleListTab />
    </Page>
  );
};
