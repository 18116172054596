import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { useNavigate } from "#src/Routers/hooks";
import { TemplatedReportsListRoutePath } from "#src/routes/reports/templated-reports";
import { TemplatedReportsCategoriesDetailRoutePath } from "#src/routes/reports/templated-reports/categories/[categoryId]";
import { TemplatedReportTemplatesTablePanel } from "#src/routes/reports/templated-reports/TemplatedReportTemplatesTablePanel";
import { ExceptionUtils } from "#src/utils/exception";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DropdownMenu,
  Form,
  Icon,
  Page,
  TextAreaInput,
  TextInput,
  useForm,
  useToast,
  type SubmitHandler,
} from "@validereinc/common-components";
import {
  BaseError,
  ResourceDefinitions,
  Resources,
  TemplatedReportAdapter,
  UpdateOneTemplatedReportCategorySchema,
  type HTTPNotFoundErrorCtx,
  type UpdateOneTemplatedReportCategoryType,
} from "@validereinc/domain";
import React, { useState } from "react";
import { useParams } from "react-router";

export const TemplatedReportsCategoriesDetailPage = () => {
  const { categoryId } =
    useParams<
      Exclude<
        typeof TemplatedReportsCategoriesDetailRoutePath.pathParams,
        undefined
      >
    >();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const detailsQuery = useQuery({
    queryKey: [Resources.TEMPLATED_REPORT, "categories", categoryId],
    queryFn: () => {
      if (!categoryId) return;
      return TemplatedReportAdapter.categories.getOne({ id: categoryId });
    },
    enabled: !!categoryId,
    select: (resp) => resp?.data,
    staleTime: 5 * 60 * 1000,
    meta: {
      hideErrorToasts: true,
      errorCtx: {
        resource: `${ResourceDefinitions.templated_report.label.singular} Category`,
        resourceId: categoryId,
      } satisfies HTTPNotFoundErrorCtx,
    },
  });
  const editCategoryForm = useForm<UpdateOneTemplatedReportCategoryType>({
    defaultValues: {
      name: detailsQuery.data?.name,
      description: detailsQuery.data?.description,
    },
  });
  const [breadcrumbs, breadcrumbsIsLoading] = useBreadcrumbsFromRoute(
    TemplatedReportsCategoriesDetailRoutePath,
    {
      "/:categoryId": {
        title: detailsQuery.data?.name,
      },
    }
  );
  const createCategoryMutation = useMutation({
    mutationFn: (
      params: Parameters<typeof TemplatedReportAdapter.categories.updateOne>[0]
    ) => {
      const vResult = UpdateOneTemplatedReportCategorySchema.safeParse(params);

      if (vResult.error) {
        throw new BaseError(
          "Failed to validate update templated report category form data.",
          {
            errors: [vResult.error],
          }
        );
      }

      return TemplatedReportAdapter.categories.updateOne({
        id: params.id,
        data: params.data,
      });
    },
    onSuccess: (data, vars) => {
      setIsEditDialogOpen(false);
      toast.push({
        intent: "success",
        description: `Successfully updated category ${data.data.name}${vars.previousData?.name && vars.previousData.name !== data.data.name ? ` (previously ${vars.previousData.name})` : ""}.`,
      });
      queryClient.invalidateQueries({
        queryKey: [Resources.TEMPLATED_REPORT, "categories"],
      });
      editCategoryForm.reset(
        {},
        {
          keepDefaultValues: true,
        }
      );
    },
    onError: (err, vars) => {
      addAlert({
        variant: "error",
        message: `Failed to update category${vars.previousData?.name ? ` ${vars.previousData.name}` : ""}.`,
      });
      ExceptionUtils.reportException(err, "error", {
        sourceComponent: "TemplatedReportsCategoriesDetailPage",
      });
    },
  });
  const deleteCategoryMutation = useMutation({
    mutationFn: (
      params: Parameters<typeof TemplatedReportAdapter.categories.deleteOne>[0]
    ) => {
      return TemplatedReportAdapter.categories.deleteOne(params);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [Resources.TEMPLATED_REPORT, "categories"],
      });
      addAlert({
        variant: "success",
        message: `Successfully deleted category${variables.previousData?.name ? ` ${variables.previousData.name}.` : ""}`,
      });
      navigate(
        TemplatedReportsListRoutePath.toLinkParts({
          queryParams: {
            tab: "categories",
          },
        })
      );
    },
    onError: (err) => {
      addAlert({
        variant: "error",
        message: "Failed to delete category.",
      });
      ExceptionUtils.reportException(err, "error", {
        sourceComponent: "TemplatedReportsCategoriesDetailsPanel",
      });
    },
  });

  const handleEditCategorySubmit: SubmitHandler<
    UpdateOneTemplatedReportCategoryType
  > = (formValues) => {
    if (!categoryId) return;

    createCategoryMutation.mutate({
      id: categoryId,
      data: formValues,
      previousData: detailsQuery.data,
    });
  };

  const editCategoryFormInputKeys =
    UpdateOneTemplatedReportCategorySchema.keyof().Enum;

  return (
    <>
      <Page
        title={
          detailsQuery.data?.name ??
          TemplatedReportsCategoriesDetailRoutePath.title
        }
        category={TemplatedReportsCategoriesDetailRoutePath.previous?.title}
        breadcrumbs={breadcrumbs}
        isLoading={breadcrumbsIsLoading}
        renderMeta={() => <>{detailsQuery.data?.description}</>}
        actionRow={
          <DropdownMenu
            options={[
              {
                label: "Edit",
                isDisabled: !categoryId,
                slotLeft: ({ iconProps }) => (
                  <Icon
                    {...iconProps}
                    variant="pencil-simple"
                  />
                ),
                onClick: () => setIsEditDialogOpen(true),
              },
              {
                isSeparator: true,
              },
              {
                label: "Delete",
                variant: "danger",
                isDisabled: !categoryId || deleteCategoryMutation.isLoading,
                slotLeft: ({ iconProps }) => (
                  <Icon
                    {...iconProps}
                    variant="trash"
                  />
                ),
                onClick: () => setIsDeleteConfirmationOpen(true),
              },
            ]}
          >
            <Button
              variant="outline"
              icon="dots-three-vertical"
            />
          </DropdownMenu>
        }
        error={detailsQuery.error}
        onErrorRender={({ error }) => <PageErrorContent error={error} />}
      >
        <TemplatedReportTemplatesTablePanel
          templatesListFilters={{
            "category.id": categoryId,
          }}
        />
      </Page>
      <Dialog
        title="Edit Category"
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onCancel={() =>
          editCategoryForm.reset(
            {},
            {
              keepDefaultValues: true,
            }
          )
        }
        actionRow={[
          <Button
            key="update"
            variant="primary"
            isLoading={createCategoryMutation.isLoading}
            onClick={() => {
              handleEditCategorySubmit(editCategoryForm.getValues());
            }}
          >
            Update
          </Button>,
        ]}
      >
        <Form {...editCategoryForm}>
          <TextInput
            name={editCategoryFormInputKeys.name}
            label="Name"
            placeholder="Enter Name..."
            isRequired
          />
          <TextAreaInput
            name={editCategoryFormInputKeys.description}
            label="Description"
            isRequired={false}
            placeholder="Enter Description..."
          />
        </Form>
      </Dialog>
      <Dialog
        title="Delete Category?"
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        actionRow={[
          <Button
            key="submit"
            variant="error"
            isLoading={deleteCategoryMutation.isLoading}
            onClick={() => {
              if (!categoryId) return;

              deleteCategoryMutation.mutate({
                id: categoryId,
                previousData: detailsQuery.data,
              });
            }}
          >
            Yes, Delete
          </Button>,
        ]}
      >
        All templates and report runs will be preserved but they will not longer
        be associated to a category. You may add the templates to another
        category later if you so choose.
      </Dialog>
    </>
  );
};
