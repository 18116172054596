import { DateRange } from "#src/batteries-included-components/Forms/DateRange";
import { useMutation } from "@tanstack/react-query";
import {
  ButtonToggleGroup,
  Dialog,
  DropdownInput,
  Form,
  FormButton,
  TextInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import type {
  AssetTypeType,
  EstimationMethodStatusType,
} from "@validereinc/domain";
import {
  EstimationMethodDomain,
  EstimationMethodStatus,
  ReportingGroupDomain,
  ReportingGroupType,
} from "@validereinc/domain";
import { yearMonthFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import startOfMonth from "date-fns/startOfMonth";
import startCase from "lodash/startCase";
import React, { useEffect, useState } from "react";
import { EstimationMethodWithUserDataType } from "../../Layouts/EstimationMethod/Detail";
import styles from "./EstimationMethodEditDialog.module.scss";
const cx = classNames.bind(styles);

export const Tab = {
  STATUS: "status",
  REPORTING_GROUP: "reporting_group",
} as const;

type FormDataType = {
  name: string;
  reportingGroupId: string[];
  status: string;
  dateRange: {
    from?: Date;
    to?: Date;
  };
  hasDateRange: boolean;
};

export type TabType = (typeof Tab)[keyof typeof Tab];

export const EstimationMethodEditDialog = ({
  entityType,
  onSubmit,
  onClose,
  isEditDialogOpen,
  method,
  reportingGroups,
}: {
  method: EstimationMethodWithUserDataType | undefined;
  isEditDialogOpen?: boolean;
  reportingGroups: ReportingGroupType[];
  entityType?: AssetTypeType;
  onSubmit?: () => void;
  onClose?: () => void;
}) => {
  const [isConfirmationDialogeOpen, setIsConfirmationDialogeOpen] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState<TabType>(Tab.STATUS);
  const { toast } = useToast();

  const form = useForm<FormDataType>({
    defaultValues: {
      name: method?.name,
      reportingGroupId:
        method?.reporting_groups?.map((group) => group?.id) ?? [],
      hasDateRange: false,
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (inputs: {
      name: string;
      reportingGroupId: string[];
      status: string;
      dateRange: {
        from?: Date;
        to?: Date;
      };
      hasDateRangeStart?: boolean;
      hasDateRangeEnd?: boolean;
    }) => {
      try {
        if (!method || !entityType) {
          throw new Error("Estimation method identifiers unavailable");
        }

        await EstimationMethodDomain.update({
          id: method.id,
          data: {
            name: inputs.name,
            status: inputs.status as EstimationMethodStatusType,
            start_date:
              inputs.dateRange?.from && inputs.hasDateRangeStart
                ? yearMonthFormatter(startOfMonth(inputs.dateRange.from))
                : undefined,
            end_date:
              inputs.dateRange?.to && inputs.hasDateRangeEnd
                ? yearMonthFormatter(startOfMonth(inputs.dateRange?.to))
                : undefined,
            delete_configurations_and_results: true,
          },
          meta: { entityType },
        });

        if (selectedTab === Tab.REPORTING_GROUP) {
          await ReportingGroupDomain.smartUpdate({
            methodId: method?.id,
            currentReportingGroup: method.reporting_groups,
            updatedReportingGroups: inputs?.reportingGroupId,
          });
        }

        onClose?.();
        setIsConfirmationDialogeOpen(false);
        onSubmit?.();
        toast.push({
          intent: "success",
          description: `Updated ${inputs.name}`,
        });
      } catch (err) {
        toast.push({
          intent: "error",
          description: `Unable to update ${inputs.name}`,
        });
      }
    },
  });

  const handleContentSwitch = (tab: TabType) => {
    form.reset();
    setSelectedTab(tab);
  };

  const onInitialFormSubmit = () => {
    if (form.getValues()?.status === EstimationMethodStatus.INACTIVE) {
      setIsConfirmationDialogeOpen(true);
    } else {
      form.handleSubmit(mutate)();
    }
  };

  useEffect(() => {
    form.reset();
  }, [isEditDialogOpen]);

  const statusOptions = [
    {
      id: EstimationMethodStatus.ACTIVE,
      name: startCase(EstimationMethodStatus.ACTIVE),
    },
    {
      id: EstimationMethodStatus.INACTIVE,
      name: startCase(EstimationMethodStatus.INACTIVE),
    },
  ];

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(mutate)}
    >
      <Dialog
        isOpen={isEditDialogOpen && !isConfirmationDialogeOpen}
        onClose={onClose}
        title={"Edit Estimation Method"}
        actionRow={[
          <FormButton
            key="save-default-values"
            variant="primary"
            onClick={onInitialFormSubmit}
            isLoading={isLoading}
          >
            Save
          </FormButton>,
        ]}
      >
        <ButtonToggleGroup
          className={cx("content-switcher")}
          activeKey={selectedTab}
          value={selectedTab}
          items={[
            {
              label: "Primary Attributes",
              dataKey: Tab.STATUS,
            },
            {
              label: "Secondary Attributes",
              dataKey: Tab.REPORTING_GROUP,
            },
          ]}
          onChange={(tab) => handleContentSwitch(tab as TabType)}
        />
        <div>
          {selectedTab === Tab.STATUS ? (
            <>
              <DropdownInput
                name={"status"}
                label={"Status"}
                options={statusOptions}
                isMulti={false}
                isFluid
                isSearchable={false}
                isRequired
                valueKey="id"
                labelKey="name"
              />
              <DateRange />
            </>
          ) : null}
          {selectedTab === Tab.REPORTING_GROUP ? (
            <>
              <TextInput
                name="name"
                label="Estimation Method Name"
                isRequired
                isDisabled={isLoading}
              />
              <DropdownInput
                name="reportingGroupId"
                label="Reporting Scenario"
                options={reportingGroups}
                labelKey="name"
                valueKey="id"
                isMulti={true}
                isFluid
                isSearchable
                isDisabled={isLoading}
              />
            </>
          ) : null}
        </div>
      </Dialog>
      <Dialog
        isOpen={isConfirmationDialogeOpen}
        onClose={() => setIsConfirmationDialogeOpen(false)}
        title={`Change historical statuses to "Inactive"?`}
        actionRow={[
          <FormButton
            key="save-default-values"
            variant="primary"
            onClick={form.handleSubmit(mutate)}
            isLoading={isLoading}
          >
            Change and Save
          </FormButton>,
        ]}
      >
        Changing the status to &quot;Inactive&quot; for any given time period
        will delete all of that period&apos;s calculation results for this
        estimation method. This action cannot be undone.
      </Dialog>
    </Form>
  );
};
