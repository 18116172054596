import { useEstimationMethodDetailContext } from "#batteries-included-components/Layouts/EstimationMethod/Detail/EstimationMethodDetailContext";
import {
  useGetEstimationMethodConfiguration,
  useGetEstimationMethodRun,
  useRunEstimationMethod,
  useUpdateEstimationMethodConfiguration,
} from "#components/hooks/useEstimationMethod";
import config from "#config";
import { MUTATION_STATUS, QUERY_STATUS } from "#constants";
import { useSearchParams } from "#routers/hooks";
import { InputSourceFlair } from "#src/batteries-included-components/Flairs/InputSource";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Stack, Warning } from "@phosphor-icons/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  DateSelectorInput,
  EmptyState,
  Form,
  KeyValuePanel,
  Link,
  Panel,
  useForm,
  useToast,
} from "@validereinc/common-components";
import {
  CalculationOutputType,
  CalculationParameterType,
  CalculatorResultsDomain,
  EstimationMethodDomain,
  EstimationMethodInputType,
} from "@validereinc/domain";
import {
  getFormattedNumberWithUnit,
  toFlattenedObject,
  yearMonthFormatter,
  yearMonthParser,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import endOfMonth from "date-fns/endOfMonth";
import format from "date-fns/format";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import startOfMonth from "date-fns/startOfMonth";
import React, { SyntheticEvent, useEffect, useMemo } from "react";
import {
  FILTER_CONFIG,
  FILTER_CONFIG_NAME_KEYS,
  TRANSLATIONS,
  getCalculationParameterInput,
} from "../helpers";
import styles from "./EstimationMethodCalculationsTab.module.scss";

const cx = classNames.bind(styles);

const EstimationMethodCalculationsTab = ({
  onClickSavedResultRecord,
}: {
  onClickSavedResultRecord: (
    methodId?: string,
    entityId?: string,
    yearMonth?: string
  ) => void;
}) => {
  const [{ yearMonth }] = useSearchParams();
  const {
    getUnitName,
    getTypeName,
    getPrecisionByType,
    getUnitByType,
    isLoading,
  } = useMeasurementTypes();
  const { method, calculatorVersion, calculator, entityType, entityId } =
    useEstimationMethodDetailContext();
  // state & hooks
  const { toast } = useToast();
  const formProps = useForm();
  const {
    formState: { dirtyFields, isValid },
    watch,
    setValue,
    getValues,
    resetField,
    clearErrors,
    trigger,
  } = formProps;
  const { from: month } = watch(FILTER_CONFIG.month.name) ?? {};
  // get saved input configuration, if any previously saved
  const [inputConfig, inputConfigFetchState, refetchConfig] =
    useGetEstimationMethodConfiguration(method?.data?.id, month, entityType);
  // save new input configuration
  const {
    update: saveInputConfig,
    status: savingStatus,
    data: inputConfigSaved,
  } = useUpdateEstimationMethodConfiguration();
  // calculation preview
  const {
    data: calcPreviewResults,
    status: calcPreviewResultsFetchState,
    update: runEstimationMethodPreview,
    setData: setCalcPreviewResults,
  } = useRunEstimationMethod(method?.data?.id, month, entityType, true, false);
  // calculation that saves as a "record" (not a preview!)
  const { status: calcResultsFetchState, update: runEstimationMethod } =
    useRunEstimationMethod(method?.data?.id, month, entityType, false, false);
  // get a "record" for the selected month, if any
  const {
    data: currentRecord,
    status: currentRecordFetchState,
    query: refetchCurrentRecord,
  } = useGetEstimationMethodRun(method?.data?.id, month, entityType);

  const resultSummaryParams = {
    filters: {
      "estimation_method.id": method?.data?.id,
      year_month: [yearMonthFormatter(month ?? new Date())],
    },
  };

  const resultSummaryQuery = useQuery({
    queryKey: ["calculatorResults", resultSummaryParams],
    queryFn: () => CalculatorResultsDomain.getList(resultSummaryParams),
  });

  const results = resultSummaryQuery.data?.data[0]?.measurement;

  const applyDefaults = useMutation({
    mutationFn: async () => {
      try {
        if (!method?.data?.id || !method?.data?.entity_type) {
          throw new Error("Estimation method unavailable");
        }
        if (inputConfigFetchState === QUERY_STATUS.ERROR) {
          // If the configuration hasn't been saved yet, we need to create it before applying defaults
          await trySaveInputConfig(true);
        }
        await EstimationMethodDomain.configuration.applyDefaults({
          id: method?.data?.id,
          meta: {
            entityType: method?.data?.entity_type,
            yearMonth: yearMonthFormatter(month),
          },
        });
        refetchConfig();
        toast.push({
          intent: "success",
          description: TRANSLATIONS.messaging.applyDefaults.success,
        });
      } catch (error) {
        console.error(error);
        toast.push({
          intent: "error",
          description: TRANSLATIONS.messaging.applyDefaults.error,
        });
      }
    },
  });

  // methods & effects
  const resetDirtyFields = (keepValue = false) => {
    // reset all dirtied fields
    const dirtyFieldsFlattened = toFlattenedObject(dirtyFields, {
      transformObjectValue: (val) => val?.id,
    });

    if (!Object.keys(dirtyFieldsFlattened).length) {
      return;
    }

    Object.keys(dirtyFieldsFlattened).forEach((key) => {
      // don't reset filters on the page
      if (FILTER_CONFIG_NAME_KEYS.includes(key)) {
        return;
      }

      // reset to "" if keepValue is not true, otherwise reset to current value
      resetField(key, {
        defaultValue: keepValue ? getValues(key) : "",
      });
    });
  };
  const resetFields = () => {
    // reset all fields
    const allFieldsFlattened = toFlattenedObject(getValues(), {
      transformObjectValue: (val) => val?.id,
    });

    if (!Object.keys(allFieldsFlattened).length) {
      return;
    }

    Object.keys(allFieldsFlattened).forEach((key) => {
      // don't reset filters on the page
      if (FILTER_CONFIG_NAME_KEYS.includes(key)) {
        return;
      }

      resetField(key, {
        defaultValue: "",
      });
    });
  };
  const getFormValuesForSubmission = () => {
    const flatFormValues = toFlattenedObject(getValues(), {
      transformObjectValue: (val) => val?.id,
    });

    return Object.entries(flatFormValues).reduce<
      Record<string, EstimationMethodInputType>
    >((formValues, [key, value]) => {
      // filter out filter form values or values that are not strings
      if (FILTER_CONFIG_NAME_KEYS.includes(key) || typeof value !== "string") {
        return formValues;
      }

      // Send NULL to the backend to clear field values
      if (value === "") {
        formValues[key] = null;
        return formValues;
      }

      const param = calculatorVersion?.calculation_parameters.find(
        ({ id }) => id === key
      );

      formValues[key] = {
        // TODO: Sometimes we get invalid measurement types from analytics
        // measurement_quantity: param.measurement_quantity,
        // measurement_type: param.measurement_type,
        value,
        unit: param?.measurement_unit,
      };
      return formValues;
    }, {});
  };
  const trySaveInputConfig = async (hideAlert = false) => {
    const formValues = getFormValuesForSubmission();

    try {
      if (!method?.data?.id) {
        throw new Error("Estimation Method ID not available");
      }

      if (!defaultCalculator) {
        throw new Error("Default Calculator not available");
      }

      await saveInputConfig(method.data.id, month, entityType, {
        calculatorVersion: defaultCalculator.version,
        inputs: formValues,
      });
      if (!hideAlert) {
        toast.push({
          intent: "success",
          description: TRANSLATIONS.messaging.inputConfig.update.success,
        });
      }
    } catch (err) {
      toast.push({
        intent: "error",
        description: TRANSLATIONS.messaging.inputConfig.update.error,
      });
    }
  };
  const changeMonth = () => {
    // first, clear errors
    clearErrors();

    // then, clear inputs
    resetFields();

    // clear preview data and current record data
    setCalcPreviewResults(undefined);
  };
  const onCalculatePreview = async (): Promise<void> => {
    // run validation on all fields
    await trigger();

    try {
      await trySaveInputConfig();

      if (!isValid || !method?.data?.id) {
        toast.push({
          intent: "error",
          description: TRANSLATIONS.messaging.calculation.validation.error,
        });
        return;
      }

      await runEstimationMethodPreview(method.data.id, month, true);
      resetDirtyFields(true);
    } catch (err) {
      toast.push({
        intent: "error",
        description: TRANSLATIONS.messaging.calculation.update.error,
      });
    }
  };

  const onSaveResults = async (): Promise<void> => {
    // run validation on all fields
    await trigger();

    try {
      await trySaveInputConfig();

      if (!isValid || !method?.data?.id) {
        toast.push({
          intent: "error",
          description: TRANSLATIONS.messaging.calculation.validation.error,
        });
        return;
      }

      await runEstimationMethod(method.data.id, month, false);

      toast.push({
        intent: "success",
        description: TRANSLATIONS.messaging.calculation.create.success,
      });

      await refetchCurrentRecord(method.data.id, month);
      resultSummaryQuery.refetch();
      resetDirtyFields(true);
    } catch (err) {
      toast.push({
        intent: "error",
        description: TRANSLATIONS.messaging.calculation.create.error,
      });
    }
  };
  const onSubmit = async (
    _: unknown,
    e: SyntheticEvent<HTMLButtonElement, SubmitEvent>
  ) => {
    // preview calculation?
    if (e.nativeEvent.submitter?.dataset.isPreview) {
      await onCalculatePreview();
    } else {
      // or an actual one?
      await onSaveResults();
    }
  };
  useEffect(() => {
    // if the query is not success, exit
    if (inputConfigFetchState !== QUERY_STATUS.SUCCESS) {
      return;
    }

    // if the config data is invalid, exit
    if (!inputConfig || !inputConfig?.inputs) {
      return;
    }

    // if the config data isn't for the current month, exit
    if (
      inputConfig.year_month &&
      inputConfig.year_month !== format(month, "yyyyMM")
    ) {
      return;
    }

    // set the saved config values to the rendered parameters. any saved values that don't map to an actual parameter will be ignored.
    Object.keys(inputConfig.inputs).forEach((key) => {
      setValue(key, inputConfig.inputs[key]?.value, {
        shouldValidate: true,
      });
    });
  }, [inputConfig, inputConfigFetchState]);

  // computed
  const calculatorDetail = calculator?.data;
  const defaultCalculator = useMemo(
    () =>
      calculatorDetail?.versions.find(
        ({ version }) => version === calculatorDetail?.default_version
      ),
    [calculatorDetail?.default_version]
  );
  const detailsData = useMemo(
    () =>
      currentRecordFetchState !== QUERY_STATUS.ERROR
        ? [
            {
              title:
                TRANSLATIONS.content.contextPlayground.savedInfoPanel.lastSaved,
              value: getTimeStringFromDate(
                currentRecord?.updated_at,
                config.DATETIME_FORMAT_READABLE
              ),
            },
            {
              title:
                TRANSLATIONS.content.contextPlayground.savedInfoPanel.savedBy,
              value: currentRecord?.updatedBy?.name ?? "-",
            },
            {
              title:
                TRANSLATIONS.content.contextPlayground.savedInfoPanel.period,
              value:
                currentRecord?.year_month &&
                currentRecord?.calculation_start ? (
                  <Link
                    label={format(
                      parse(
                        currentRecord?.year_month,
                        "yyyyMM",
                        parseISO(currentRecord?.calculation_start)
                      ),
                      "MMM yyyy"
                    )}
                    onClick={() =>
                      onClickSavedResultRecord(
                        method?.data?.id,
                        entityId,
                        currentRecord?.year_month
                      )
                    }
                  />
                ) : null,
            },
          ]
        : [
            {
              title:
                TRANSLATIONS.content.contextPlayground.savedInfoPanel.lastSaved,
              value: "-",
            },
            {
              title:
                TRANSLATIONS.content.contextPlayground.savedInfoPanel.savedBy,
              value: "-",
            },
            {
              title:
                TRANSLATIONS.content.contextPlayground.savedInfoPanel.period,
              value: "-",
            },
          ],
    [currentRecord, currentRecordFetchState]
  );
  const calculationParameterInputs = useMemo(() => {
    const { calculation_parameters: calculatorParameters } =
      defaultCalculator ?? {};

    if (!defaultCalculator || !calculatorParameters) {
      return null;
    }

    return calculatorParameters.map((parameter: CalculationParameterType) => (
      <div
        className={cx("formInputWithInfo")}
        key={parameter.id}
      >
        {getCalculationParameterInput({
          ...parameter,
          measurement_unit: getUnitName(parameter.measurement_unit),
        })}
        {/* only show the input source flair when a valid configuration exists */}
        {inputConfigFetchState === QUERY_STATUS.SUCCESS ? (
          <InputSourceFlair
            inputParameter={parameter}
            estimationMethodConfig={inputConfig}
          />
        ) : null}
      </div>
    ));
  }, [calculatorDetail?.id, inputConfig, inputConfigFetchState]);
  const mapOutputsToKeyValuePanelData = (
    runOutputs?: CalculationOutputType[]
  ) =>
    Array.isArray(runOutputs) && runOutputs?.length
      ? runOutputs.map((outputResult: CalculationOutputType) => ({
          title: getTypeName(outputResult.measurement_type),
          value: getFormattedNumberWithUnit(
            {
              value: Number(outputResult.measurement_value),
              unit: getUnitName(outputResult.measurement_unit),
            },
            getPrecisionByType(outputResult.measurement_type),
            {
              showSmallNumberAsExponential: true,
              maxFractionDigits: 3,
            }
          ),
        }))
      : [];
  const mappedCalcPreviewResults = useMemo(
    () =>
      mapOutputsToKeyValuePanelData(calcPreviewResults?.output?.outputs) ?? [],
    [calcPreviewResultsFetchState, calcPreviewResults]
  );

  const mappedCurrentRecord = useMemo(
    () =>
      results
        ? Object.entries(results).map(([key, value]) => ({
            title: getTypeName(key),
            value: getFormattedNumberWithUnit(
              {
                value: Number(value),
                unit: getUnitName(getUnitByType(key)),
              },
              getPrecisionByType(key),
              {
                showSmallNumberAsExponential: true,
                maxFractionDigits: 3,
              }
            ),
          }))
        : [],
    [results, isLoading]
  );

  const isPreviewCalcSaved =
    currentRecord &&
    currentRecord?.year_month === format(month, "yyyyMM") &&
    calcPreviewResults?.input.calculation_parameters.every((param) =>
      currentRecord.input.calculation_parameters.some(
        (savedParam) =>
          savedParam.id === param.id &&
          savedParam.measurement_value === param.measurement_value
      )
    ) &&
    calcPreviewResults?.output.outputs.every((param) =>
      currentRecord.output.outputs.some(
        (savedParam) =>
          savedParam.measurement_type === param.measurement_type &&
          savedParam.measurement_value === param.measurement_value
      )
    );
  const areCalcInputsDirtied =
    Object.keys(dirtyFields).filter(
      (key) => !FILTER_CONFIG_NAME_KEYS.includes(key)
    ).length > 0;
  const mergedInputConfig = {
    ...(inputConfig || {}),
    ...(inputConfigSaved || {}),
  };
  const inputPlaygroundActions = (
    <>
      <Button
        isLoading={
          savingStatus === MUTATION_STATUS.LOADING ||
          calcPreviewResultsFetchState === MUTATION_STATUS.LOADING ||
          applyDefaults.isLoading
        }
        onClick={() => applyDefaults.mutate()}
      >
        {TRANSLATIONS.actions.applyDefaults}
      </Button>
      <Button
        variant="primary"
        type="submit"
        isLoading={
          savingStatus === MUTATION_STATUS.LOADING ||
          calcPreviewResultsFetchState === MUTATION_STATUS.LOADING ||
          applyDefaults.isLoading
        }
        data-is-preview
      >
        {TRANSLATIONS.actions.preview}
      </Button>
    </>
  );
  const previewPlaygroundActions = (
    <Button
      type="submit"
      variant="primary"
      isLoading={
        savingStatus === MUTATION_STATUS.LOADING ||
        calcResultsFetchState === MUTATION_STATUS.LOADING ||
        currentRecordFetchState === QUERY_STATUS.LOADING
      }
    >
      {TRANSLATIONS.actions.saveRecord}
    </Button>
  );

  /** Read the date from the parameters only on initial load */
  const defaultPeriod = useMemo(
    () => (yearMonth ? yearMonthParser(yearMonth) : new Date()),
    []
  );

  return (
    <div className={cx("container")}>
      <Form
        {...formProps}
        onSubmit={onSubmit}
      >
        <div
          role="region"
          aria-label="Filter Area"
          className={cx("filterPanel")}
        >
          <DateSelectorInput
            name={FILTER_CONFIG.month.name}
            defaultValue={{
              from: startOfMonth(defaultPeriod),
              to: endOfMonth(defaultPeriod),
            }}
            variant="month"
            isRange={false}
            isInline
            hasNextPrevButtons
            onChange={changeMonth}
          />
        </div>

        <div className={cx("playground")}>
          <Panel
            title={
              <div className={cx("titleWithFlair")}>
                <span>{TRANSLATIONS.content.inputPlayground.title}</span>
                <span className={cx("titleFlair")}>
                  {TRANSLATIONS.content.inputPlayground.saved(
                    getTimeStringFromDate(
                      mergedInputConfig.updated_at,
                      config.DATETIME_FORMAT_READABLE
                    ),
                    mergedInputConfig.updatedBy?.name
                  )}
                </span>
              </div>
            }
            className={cx("inputPlayground")}
            actionRow={
              !mappedCalcPreviewResults?.length || areCalcInputsDirtied
                ? inputPlaygroundActions
                : null
            }
            loaded={calculator?.status !== QUERY_STATUS.LOADING}
          >
            {calculator?.status === QUERY_STATUS.SUCCESS ? (
              <div className={cx("inputPlaygroundContent")}>
                {calculationParameterInputs}
              </div>
            ) : (
              <EmptyState
                title={TRANSLATIONS.content.inputPlayground.empty.title}
                suggestion={
                  TRANSLATIONS.content.inputPlayground.empty.suggestion
                }
                icon={<Warning />}
              />
            )}
          </Panel>
          <Panel
            title={TRANSLATIONS.content.previewPlayground.title}
            className={cx("previewPlayground")}
            actionRow={
              mappedCalcPreviewResults?.length && !areCalcInputsDirtied
                ? previewPlaygroundActions
                : null
            }
            loaded={calcPreviewResultsFetchState !== MUTATION_STATUS.LOADING}
          >
            {calcPreviewResultsFetchState === MUTATION_STATUS.SUCCESS &&
            mappedCalcPreviewResults?.length ? (
              <>
                <KeyValuePanel
                  isHidden={areCalcInputsDirtied}
                  panelMaxColumnCount={1}
                  panelKeyValueListProps={{
                    variant: "shaded",
                    color: "placeholder",
                  }}
                  panelKeyValueListContainerProps={{
                    style: { padding: 0 },
                  }}
                  panelProps={{
                    title: null,
                    isFluidY: false,
                    style: { padding: 0, marginTop: 8 },
                  }}
                  data={mappedCalcPreviewResults}
                />
                <EmptyState
                  title={TRANSLATIONS.content.previewPlayground.sync.title}
                  suggestion={
                    TRANSLATIONS.content.previewPlayground.sync.suggestion
                  }
                  icon={<Warning />}
                  isHidden={!areCalcInputsDirtied}
                />
              </>
            ) : (
              <EmptyState
                title={TRANSLATIONS.content.previewPlayground.empty.title}
                suggestion={
                  TRANSLATIONS.content.previewPlayground.empty.suggestion
                }
                icon={<Stack />}
              />
            )}
          </Panel>
          <Panel
            title={TRANSLATIONS.content.contextPlayground.title}
            className={cx("contextPlayground")}
            loaded={
              currentRecordFetchState !== QUERY_STATUS.LOADING &&
              calcResultsFetchState !== MUTATION_STATUS.LOADING &&
              !resultSummaryQuery.isLoading &&
              !isLoading
            }
          >
            <div className={cx("contextPlaygroundContent")}>
              {currentRecordFetchState === QUERY_STATUS.SUCCESS &&
              mappedCurrentRecord?.length ? (
                <KeyValuePanel
                  panelMaxColumnCount={1}
                  panelKeyValueListProps={{
                    variant: "shaded",
                    ...(isPreviewCalcSaved ? { color: "active" } : {}),
                  }}
                  panelKeyValueListContainerProps={{
                    style: { padding: 0 },
                  }}
                  panelProps={{
                    title: null,
                    isFluidY: false,
                    style: { margin: 0, padding: 0 },
                  }}
                  data={mappedCurrentRecord}
                />
              ) : (
                <EmptyState
                  title={TRANSLATIONS.content.contextPlayground.empty.title}
                  suggestion={
                    TRANSLATIONS.content.contextPlayground.empty.suggestion
                  }
                  icon={<Stack />}
                />
              )}
              <KeyValuePanel
                panelMaxColumnCount={1}
                panelKeyValueListProps={{
                  variant: "shaded",
                }}
                panelKeyValueListContainerProps={{
                  style: { padding: 0 },
                }}
                panelProps={{
                  title: null,
                  isFluidY: false,
                  className: cx("detailsPlayground"),
                  style: { margin: 0, padding: 0 },
                }}
                data={detailsData}
              />
            </div>
          </Panel>
        </div>
      </Form>
    </div>
  );
};

export default EstimationMethodCalculationsTab;
