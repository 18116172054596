const breakpoints = require("./breakpoints-vars.json");

module.exports = {
  breakpoints,
  customProperties: {
    "--breakpoint-width-xs": breakpoints.widths.xs,
    "--breakpoint-width-sm": breakpoints.widths.sm,
    "--breakpoint-width-md": breakpoints.widths.md,
    "--breakpoint-width-lg": breakpoints.widths.lg,
    "--breakpoint-width-xl": breakpoints.widths.xl,
    "--grid-columns": breakpoints.gridColumns,
    "--grid-margin-gutters": breakpoints.gridMarginGutters,
  },
};
