import { AssetTypeType } from "../../schemas";
import { restAPI } from "../api";

export const RecordValueStatus = {
  Locked: "locked",
  Unlocked: "unlocked",
} as const;

export type RecordValueStatusType =
  (typeof RecordValueStatus)[keyof typeof RecordValueStatus];

export type RecordValueLockReponse = { success: boolean };

export type RecordValueType = {
  id?: string;
  record_id?: string;
  value: number | null;
  measurement_unit: string;
  measurement_type: string;
  configuration: RecordValueConfigurationType;
  default_record_value_config_id?: string;
  note?: string;
  status?: RecordValueStatusType;
  attachment_ref?: string;
  attachment_filename?: string;
  updated_at?: string;
  updated_by?: string;
};

export const AggregationFunctionType = {
  AVERAGE: "avg",
  SUM: "sum",
  TOP: "top",
};

export type AggregationFunctionTypeType =
  (typeof AggregationFunctionType)[keyof typeof AggregationFunctionType];

export const AggregationFunctionTypeNames = {
  [AggregationFunctionType.AVERAGE]: "Average",
  [AggregationFunctionType.SUM]: "Sum",
  [AggregationFunctionType.TOP]: "Most Recent",
};

export const formatAggregationFunctionType = (
  type?: AggregationFunctionTypeType
) => (type ? AggregationFunctionTypeNames[type] : "-");

export const RecordValueConfigurationTypeType = {
  MANUAL: "manual",
  CALCULATION_RESULT: "calculator_result",
  MEASUREMENT: "form_measurement",
  MEASUREMENT_SERIES: "measurement_series",
  RECORD: "record",
};

export const RecordValueConfigurationTypeNames = {
  [RecordValueConfigurationTypeType.MANUAL]: "Manual",
  [RecordValueConfigurationTypeType.CALCULATION_RESULT]: "Calculation",
  [RecordValueConfigurationTypeType.MEASUREMENT]: "Measurement",
  [RecordValueConfigurationTypeType.MEASUREMENT_SERIES]: "Device",
  [RecordValueConfigurationTypeType.RECORD]: "Records",
};

export const formatRecordValueConfigurationType = (
  type?: RecordValueConfigurationTypeTypeType
) => (type ? RecordValueConfigurationTypeNames[type] : "-");

export type RecordValueConfigurationTypeTypeType =
  (typeof RecordValueConfigurationTypeType)[keyof typeof RecordValueConfigurationTypeType];

export type RecordValueConfigurationBaseType = {
  configuration_type: RecordValueConfigurationTypeTypeType;
  aggregate_function?: AggregationFunctionTypeType;
  sources: RecordValueConfigurationSourceType[];
};

export type RecordValueConfigurationSourceType = {
  id?: string;
  device_id?: string;
  device_name?: string;
  form_schema_name?: string;
  form_submission_id: string;
  form_category_id?: string;
  measurement_time?: string;
  measurement_type?: string;
  unit: string;
  value: number;
  estimation_method_entity_type?: AssetTypeType;
  estimation_method_entity_id?: string /** We need to seed this ourselves for now */;
  estimation_method_name?: string;
  estimation_method_id?: string;
  year_month?: string;
};

export type ManualRecordValueConfigurationType =
  RecordValueConfigurationBaseType & { value: number };

export type CalculationRecordValueConfigurationType =
  RecordValueConfigurationBaseType & {
    calculator_result_ids: string[];
  };

export type MeasurementRecordValueConfigurationType =
  RecordValueConfigurationBaseType & {
    form_category_id: string;
    measurement_ids: string[];
  };

export type MeasurementSeriesRecordValueConfigurationType =
  RecordValueConfigurationBaseType & {
    measurement_series_id: string;
    interval: string;
    start: string;
    end: string;
  };

export type RecordValueConfigurationType = ManualRecordValueConfigurationType &
  CalculationRecordValueConfigurationType &
  MeasurementRecordValueConfigurationType &
  MeasurementSeriesRecordValueConfigurationType;

export type RecordValueLookupType = {
  recordId: string;
  measurementType: string;
};

export type RecordValueStatusUpdateType = {
  recordId: string;
  measurementType: string;
  status: RecordValueStatusType;
};

export type RecordValueUpdateType = {
  value: number;
  measurement_unit: string;
  configuration: RecordValueConfigurationType;
};

const parseConfigurationByType = ({
  configuration_type,
  aggregate_function,
  measurement_series_id,
  calculator_result_ids,
  measurement_ids,
  form_category_id,
  interval,
  start,
  end,
  value,
}: RecordValueConfigurationType) => {
  switch (configuration_type) {
    case RecordValueConfigurationTypeType.CALCULATION_RESULT:
      return {
        configuration_type,
        aggregate_function,
        calculator_result_ids,
      };
    case RecordValueConfigurationTypeType.MEASUREMENT_SERIES:
      return {
        configuration_type,
        aggregate_function,
        measurement_series_id,
        start,
        end,
        interval,
      };
    case RecordValueConfigurationTypeType.MANUAL:
      return {
        configuration_type,
        value,
      };
    default:
      return {
        configuration_type: RecordValueConfigurationTypeType.MEASUREMENT,
        aggregate_function,
        form_category_id,
        measurement_ids,
      };
  }
};

type RecordValueEntityLockType = {
  rules: Record<string, string[] | string>;
  whitelist_column: string[];
};

export const RecordValueEntityLock = (): RecordValueEntityLockType => {
  return {
    rules: {
      status: ["locked"],
    },
    whitelist_column: ["status"],
  };
};

export const RecordValueDefaultStatus = "unlocked";

export const isRecordValueLocked = (recordValue: RecordValueType) => {
  const entityLock = RecordValueEntityLock();
  return entityLock.rules.status.includes(
    recordValue?.status ?? RecordValueDefaultStatus
  );
};

export const RecordValueDomain = {
  getOne: async ({ recordId, measurementType }: RecordValueLookupType) =>
    restAPI.nodeAPI.GET<RecordValueType>({
      endpoint: `/records/${recordId}/values/${measurementType}`,
    }),
  update: async (
    { recordId, measurementType }: RecordValueLookupType,
    { configuration, ...updatedFields }: RecordValueUpdateType
  ) =>
    restAPI.nodeAPI.PUT<RecordValueType>({
      endpoint: `/records/${recordId}/value/${measurementType}`,
      body: {
        ...updatedFields,
        configuration: configuration
          ? parseConfigurationByType(configuration)
          : undefined,
      },
    }),
  delete: async ({ recordId, measurementType }: RecordValueLookupType) =>
    restAPI.nodeAPI.DELETE({
      endpoint: `/records/${recordId}/value/${measurementType}`,
    }),
  status: async ({
    recordId,
    measurementType,
    status,
  }: RecordValueStatusUpdateType) =>
    restAPI.nodeAPI.PUT<RecordValueLockReponse>({
      endpoint: `/records/${recordId}/value/${measurementType}/status`,
      body: { status },
    }),
};
